import { Node } from '../model/Node';

export class Attr<T> {
  constructor(readonly name: string, readonly def: T) {}

  get(node: Node): T {
    return node.get(this.name, this.def)!;
  }

  set(node: Node, value: T) {
    node.set(this.name, value);
  }
}

type ElementType = 'feed' | 'bkg' | 'feedInfo' | 'fiSet' | 'ovSet' | 'ovInfo' | 'none';

export const TypeAttr = new Attr<ElementType>('t', 'none');

export class Element<T extends Node> {
  readonly node: T;

  constructor(node: T) {
    this.node = node;
  }

  get modCount(): number {
    return this.node.modCount;
  }

  get id(): string {
    return this.node.id;
  }

  get type(): ElementType {
    return TypeAttr.get(this.node);
  }

  eq(element: Element<T>): boolean {
    return this.node.eq(element.node);
  }

  toJSON() {
    return this.node.toJSON();
  }
}

export class Ref<T extends Element<any>> {
  public readonly modCount;

  constructor(readonly element: T) {
    this.modCount = element.modCount;
  }

  refresh(el: T): Ref<T> {
    if (el === this.element && el.modCount === this.modCount) return this;

    return new Ref(el);
  }
}

export const newNodeNewId = (type: ElementType) => {
  return newNode(Node.newId(), type);
};

export const newNode = (id: string, type: ElementType) => {
  const node = new Node(id);
  TypeAttr.set(node, type);
  return node;
};
