import { Reducer } from 'redux';
import { getType } from '@anm/helpers/saga/typesafe-actions';

import { analyticsActions } from '..';
import {
  getAnalyticsFailureState,
  getAnalyticsRequestState,
  getAnalyticsSuccessState
} from '../helpers';
import { AnalyticsActions, AnalyticsDataState } from '../types';

const initialAnalyticsDataState: AnalyticsDataState = {
  isError: false,
  isPending: false,
  isLifetimeEmpty: false,
  data: null
};

const analyticsDataReducer: Reducer<
  typeof initialAnalyticsDataState,
  AnalyticsActions
> = (state = initialAnalyticsDataState, action) => {
  switch (action.type) {
    case getType(analyticsActions.fetchAnalyticsAsync.request): {
      return {
        ...state,
        ...getAnalyticsRequestState(action.payload)
      };
    }
    case getType(analyticsActions.fetchAnalyticsAsync.success): {
      return {
        ...state,
        ...getAnalyticsSuccessState(action.payload, state.params)
      };
    }
    case getType(analyticsActions.fetchAnalyticsAsync.failure): {
      return {
        ...state,
        ...getAnalyticsFailureState(action.payload)
      };
    }
    default:
      return state;
  }
};

export default analyticsDataReducer;
