import localStorage from '@anm/helpers/localStorage';
import { newId, ID_LENGTH } from '@anm/shared/randomstring';

export const fixDate = <T>(m: any, field: string): T => {
  if (typeof m[field] === 'string') {
    m[field] = new Date(m[field]);
  }
  return m;
};

export const fixDates = <T>(m: any, fields: string[]): T => {
  for (const field of fields) {
    fixDate(m, field);
  }
  return m;
};

export const getGuestID = () => {
  let guestId = localStorage().getItem('guest_id') || '';
  if (guestId.length !== ID_LENGTH) {
    guestId = newId();
    localStorage().setItem('guest_id', guestId);
  }

  return guestId;
};
