import { createSelector } from 'reselect';

import { ApplicationState } from '../../types';

const selectNotifications = ({ appNotifications }: ApplicationState) => appNotifications;

export const selectAppNotifications = createSelector(
  selectNotifications,
  appNotifications => appNotifications.notifications
);
