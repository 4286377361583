import { createSelector } from 'reselect';

import { isDestinationEnabled } from './helpers/isDestinationEnabled';
import { UserDestinationsList } from './types';

type AppState = {
  userDestinations: UserDestinationsList;
};

const selectState = ({ userDestinations }: AppState) => userDestinations;

export const selectDestinationsObject = createSelector(selectState, destinations => destinations?.list);

export const selectDestinationsList = createSelector(selectDestinationsObject, destinationsObject => {
  if (!destinationsObject) return null;

  return Object.keys(destinationsObject).map(k => destinationsObject[k]);
});

export const selectAddedDestinations = createSelector(selectDestinationsList, list =>
  list?.filter(d => !d.error || d.error !== 'SHOULD_ADD_APP')
);

export const selectEnabledDestinations = createSelector(selectAddedDestinations, list =>
  list?.filter(d => isDestinationEnabled(d))
);

export const selectDestinationsListIsPending = createSelector(selectState, destinations => destinations.isPending);
export const selectIsToggleDestinationPending = createSelector(
  selectState,
  destinations => destinations.toggleDestinationPending
);
export const selectDestinationsListError = createSelector(selectState, destinations => destinations.error);

export const selectDestinationsListIsFetched = createSelector(
  selectState,
  destinations => destinations.params?.isFetched
);

export const selectDestinationsListIsError = createSelector(selectState, destinations => destinations.isError);

export const selectDestinationsListParams = createSelector(selectState, destinations => destinations.params);

const selectDeleteDestinationEntity = createSelector(selectState, destinations => destinations.deleteDestination);

export const selectDeleteDestinationPending = createSelector(
  selectDeleteDestinationEntity,
  deleteDestination => deleteDestination.isPending
);

export const selectDeleteDestinationError = createSelector(
  selectDeleteDestinationEntity,
  deleteDestination => deleteDestination.isError
);

export const selectDeleteDestinationNotify = createSelector(
  selectDeleteDestinationEntity,
  deleteDestination => deleteDestination.params?.notify
);

export const selectDeleteGuestsIsPending = createSelector(
  selectState,
  ({ deleteGuestsDestinations }) => deleteGuestsDestinations.isPending
);

export const selectDeleteGuestsParams = createSelector(
  selectState,
  ({ deleteGuestsDestinations }) => deleteGuestsDestinations.params
);

export const selectCanCheckErrors = createSelector(selectState, userDestinations => userDestinations.canCheckErrors);
