import { getType } from '@anm/helpers/saga/typesafe-actions';
import { Reducer } from 'redux';

import { UserPrefsActions, UserPrefsState } from '../types';

import { fetchUserPrefsAsync, updateUserPrefsAsync } from './../actions';
import fetchUserPrefsReducer from './fetchUserPrefsReducer';
import updateUserPrefsReducer from './updateUserPrefsReducer';

export const initialUserPrefsState: UserPrefsState = {
  data: null,
  fetchUserPrefs: {
    isError: false,
    isPending: false,
    error: null
  },
  updateUserPrefs: {
    isError: false,
    isPending: false,
    error: null
  }
};

const userPrefsReducer: Reducer<UserPrefsState, UserPrefsActions> = (state = initialUserPrefsState, action) => {
  switch (action.type) {
    case getType(fetchUserPrefsAsync.request):
    case getType(fetchUserPrefsAsync.success):
    case getType(fetchUserPrefsAsync.failure):
      return fetchUserPrefsReducer(state, action);

    case getType(updateUserPrefsAsync.request):
    case getType(updateUserPrefsAsync.success):
    case getType(updateUserPrefsAsync.failure):
      return updateUserPrefsReducer(state, action);

    default:
      return state;
  }
};

export default userPrefsReducer;
