import selectProps from '@anm/helpers/store/selectProps';
import { createSelector } from 'reselect';

import { ApplicationState } from '../../types';

import { AnalyticKey } from './types';

export const selectDateRange = ({ analytics }: ApplicationState) => analytics.dateRange;

const selectPeriodData = ({ analytics }: ApplicationState) => analytics.periodData.data;

const selectPeriodDataParams = ({ analytics }: ApplicationState) => analytics.periodData.params;

export const selectPeriodDataIsPending = ({ analytics }: ApplicationState) => analytics.periodData.isPending;

export const selectPeriodDataIsError = ({ analytics }: ApplicationState) => analytics.periodData.isError;

export const selectPeriodDataIsLifetimeEmpty = ({ analytics }: ApplicationState) =>
  analytics.periodData.isLifetimeEmpty;

export const selectPeriodDataByKey = createSelector(selectPeriodData, selectProps<AnalyticKey>(), (periodData, key) => {
  return periodData?.map(item => {
    const ISODate = new Date(`${item.time.replace(' ', 'T')}Z`); // ISO format for mobile devices

    return {
      value: item[key],
      label: ISODate.getTime().toString()
    };
  });
});

export const selectIsAllChartsWasFetched = createSelector(
  selectPeriodDataParams,
  selectPeriodDataIsPending,
  (periodDataParams, periodDataIsPending) => periodDataParams && !periodDataIsPending
);

export const selectAggregateEngagementViews = createSelector(selectPeriodData, periodData => {
  const periodViews = periodData?.map(dayData => dayData.views);

  const defaultArray = Array(periodViews?.[0]?.length).fill(0);

  const aggregateViews = periodViews?.reduce((acc, dayViews) => {
    acc.forEach((_, i) => (acc[i] += dayViews[i]));

    return acc;
  }, defaultArray);

  return aggregateViews;
});

export const selectAggregateEngagement = createSelector(selectAggregateEngagementViews, aggregateViews => {
  const views = aggregateViews || [];

  const mid = views.reduce((acc, k) => acc + k, 0) / views.length;
  const max = Math.max(...views);

  return (mid * 100) / max || 0;
});

export const selectAggregateValueByKey = createSelector(
  selectPeriodData,
  selectProps<'timePlayed' | 'plays'>(),
  (periodData, key) => periodData?.map(dayData => dayData[key]).reduce((acc, item) => acc + item, 0)
);
