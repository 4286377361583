import { ApiError } from '@anm/api';
import { AnalyticsResponse } from '@anm/api/modules/analytics';

import { AnalyticsParams } from './types';

export const getAnalyticsRequestState = (params: AnalyticsParams) => ({
  params,
  error: null,
  isError: false,
  isPending: true
});

export const getAnalyticsSuccessState = (
  response: AnalyticsResponse,
  params?: AnalyticsParams
) => ({
  data: response,
  isPending: false,
  isLifetimeEmpty: !response?.length && !!params?.isLifetimeRange
});

export const getAnalyticsFailureState = (error: ApiError) => ({
  error,
  isError: true,
  isPending: false
});
