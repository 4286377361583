import asyncEntityHandlers from '@anm/helpers/redux/asyncEntityHandlers';
import reduceFactory from '@anm/helpers/redux/reduceFactory';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { streamActions, StreamActions } from '..';
import { StreamingState } from '../types';

const initialState: StreamingState = {
  streamResolution: { isError: false, isPending: false },
  stream: { isError: false, isPending: false, data: null }
};

const streamResolutionReducer: Reducer<StreamingState, StreamActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(streamActions.clearStream):
      return {
        ...state,
        stream: initialState.stream
      };

    default:
      return reduceFactory(initialState, {
        ...asyncEntityHandlers<StreamingState>(streamActions.fetchStreamAsync, 'stream'),
        ...asyncEntityHandlers<StreamingState>(streamActions.updateStreamResolutionAsync, 'stream'),
        ...asyncEntityHandlers<StreamingState>(streamActions.updateStreamResolutionAsync, 'streamResolution')
      })(state, action);
  }
};

export default streamResolutionReducer;
