import { ApiError } from '@anm/api';
import { AnalyticsResponse } from '@anm/api/modules/analytics';
import {
  createAction,
  createAsyncAction
} from '@anm/helpers/saga/typesafe-actions';

import { AnalyticsParams } from './types';

export const updateDateRange = createAction(
  'wave.video/analytics/UPDATE_DATE_RANGE'
)<number[]>();

export const fetchAnalytics = createAction('wave.video/analytics/FETCH_DATA')();

export const fetchAnalyticsAsync = createAsyncAction(
  'wave.video/analytics/FETCH_DATA_REQUEST',
  'wave.video/analytics/FETCH_DATA_SUCCESS',
  'wave.video/analytics/FETCH_DATA_FAILURE'
)<AnalyticsParams, AnalyticsResponse, ApiError>();
