import api from '@anm/api';
import { AnalyticsRequestParams } from '@anm/api/modules/analytics';
import { PLAYER_ANALYTICS_PROD, PLAYER_ANALYTICS_TEST } from '@anm/constants/domains';
import logger from '@anm/helpers/logger';
import asyncEntity from '@anm/helpers/redux/asyncEntity';
import { call, fork, select, take } from '@anm/helpers/saga/effects';
import { takeType } from '@anm/helpers/saga/typesafe-actions';

import { analyticsActions, analyticsSelectors } from '../analytics';
import { projectSelectors } from '../project';

function* getAnalyticsParams() {
  const projectId = yield* select(projectSelectors.selectCurrentProjectHostingId);

  if (!projectId) {
    logger('Can not find currentHostingId in project state');
    return;
  }
  const [startDate, endDate] = yield* select(analyticsSelectors.selectDateRange);

  const projectCreatedTime = yield* select(projectSelectors.selectCurrentProjectCreatedTime);

  const isLifetimeRange = projectCreatedTime === startDate;

  const dateParams = isLifetimeRange ? {} : { start: startDate, end: endDate };

  const payload: AnalyticsRequestParams = {
    projectId,
    ...dateParams
  };

  const requestParams = { ...payload, isLifetimeRange };

  return [payload, requestParams] as const;
}

const getAnalyticsUrl = () => {
  switch (process.env.VT_ENV) {
    case 'prod': {
      return PLAYER_ANALYTICS_PROD;
    }
    case 'dev':
    case 'test':
    default: {
      return PLAYER_ANALYTICS_TEST;
    }
  }
};

const getAnalyticsRequest = (params: AnalyticsRequestParams) =>
  api().analytics.getAnalytics({ params, analyticsUrl: getAnalyticsUrl() });

const getAnalytics = asyncEntity(analyticsActions.fetchAnalyticsAsync, getAnalyticsRequest);

function* fetchAnalytics() {
  const params = yield* call(getAnalyticsParams);

  if (!params) {
    logger('expect getAnalyticsParams return AnalyticsRequestParams, but return undefined');
    return;
  }

  const [payload, requestParams] = params;

  yield fork(getAnalytics, payload, requestParams);
}

function* watchFetchAnalytics() {
  while (true) {
    yield take(takeType(analyticsActions.fetchAnalytics));

    yield fork(fetchAnalytics);
  }
}

const dynamicSagaWatchers = () => [fork(watchFetchAnalytics)];

export default dynamicSagaWatchers;
