import asyncEntityHandlers from '@anm/helpers/redux/asyncEntityHandlers';
import pickRequestFailure from '@anm/helpers/redux/pickRequestFailure';
import reduceFactory from '@anm/helpers/redux/reduceFactory';
import { getType } from '@anm/helpers/saga/typesafe-actions';
import { compose } from 'lodash/fp';
import { Reducer } from 'redux';

import { projectActions } from '..';
import { setStatusesForProjects, sortProjects } from '../../videoFolder/helpers';
import { ProjectActions } from '../types';

import { initialProjectsState } from './index';

const { searchProjects, changeSearchOpen, cancelSearchProjects, fetchSearchProjectsAsync } = projectActions;

const searchProjectListReducer: Reducer<typeof initialProjectsState, ProjectActions> = (
  state = initialProjectsState,
  action
) => {
  switch (action.type) {
    case getType(changeSearchOpen): {
      return {
        ...state,
        searchProjects: {
          ...state.searchProjects,
          isSearchOpen: action.payload
        }
      };
    }
    case getType(searchProjects): {
      return {
        ...state,
        searchProjects: {
          ...state.searchProjects,
          list: [],
          search: action.payload
        }
      };
    }
    case getType(cancelSearchProjects): {
      return {
        ...state,
        searchProjects: initialProjectsState.searchProjects
      };
    }

    case getType(fetchSearchProjectsAsync.success): {
      const { list: prevList, offset, limit } = state.searchProjects;
      const { count, results = [] } = action.payload;
      const newList = compose(sortProjects, setStatusesForProjects)(results);

      return {
        ...state,
        searchProjects: {
          ...state.searchProjects,
          count,
          isProjectsEmpty: count <= 0,
          list: [...prevList, ...newList],
          offset: offset + limit,
          isPending: false
        }
      };
    }

    default:
      return reduceFactory(initialProjectsState, {
        ...asyncEntityHandlers<typeof initialProjectsState>(
          pickRequestFailure(fetchSearchProjectsAsync),
          'searchProjects'
        )
      })(state, action);
  }
};

export default searchProjectListReducer;
