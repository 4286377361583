import selectProps from '@anm/helpers/store/selectProps';
import { createSelector } from 'reselect';

import { ApplicationState } from '../../types';
import { selectVideoData } from '../hostedVideo/selectors';

const selectCurrentProjectEntity = ({ project }: ApplicationState) => project.currentProject;
const selectProjectsState = ({ project }: ApplicationState) => project.projectItems;
const selectSearchProjectsState = ({ project }: ApplicationState) => project.searchProjects;

export const selectCurrentProject = createSelector(selectCurrentProjectEntity, currentProject => currentProject.data);
export const selectProjectsCount = createSelector(selectProjectsState, ({ count }) => count);
export const selectIsProjectsEmpty = createSelector(selectProjectsState, ({ isProjectsEmpty }) => isProjectsEmpty);
export const selectIsStreamModalOpen = createSelector(
  selectProjectsState,
  ({ isStreamModalOpen }) => isStreamModalOpen
);

export const selectSearchProjectsList = createSelector(selectSearchProjectsState, ({ list }) => list);
export const selectSearchProjectsError = createSelector(selectSearchProjectsState, ({ error, isError }) => ({
  error,
  isError
}));
export const selectSearchOpenStatus = createSelector(selectSearchProjectsState, ({ isSearchOpen }) => isSearchOpen);
export const selectSearchProjectsPending = createSelector(selectSearchProjectsState, ({ isPending }) => isPending);
export const selectProjectsSearchKeyWord = createSelector(selectSearchProjectsState, ({ search }) => search);

export const selectCurrentProjectDuration = createSelector(selectCurrentProject, currentProject =>
  Math.max(currentProject?.duration || 0, currentProject?.extra.hostedDuration || 0)
);

export const selectCurrentProjectFolderId = createSelector(
  selectCurrentProject,
  currentProject => currentProject?.folderId
);

export const selectCurrentProjectPending = createSelector(
  selectCurrentProjectEntity,
  currentProject => currentProject.isPending
);

export const selectCurrentProjectError = createSelector(
  selectCurrentProjectEntity,
  currentProject => currentProject.error
);

export const selectCurrentProjectId = createSelector(selectCurrentProject, currentProject => currentProject?.id);

export const selectCurrentProjectCreatedTime = createSelector(
  selectCurrentProject,
  currentProject => currentProject?.created
);

export const selectCurrentProjectSizes = createSelector(selectCurrentProject, currentProject => ({
  width: currentProject?.width,
  height: currentProject?.height
}));

export const selectCurrentProjectDefaultPreview = createSelector(
  selectCurrentProject,
  currentProject => currentProject?.preview_metadata[0]
);

export const selectProjectById = createSelector(
  selectCurrentProject,
  selectProps<string>(),
  (currentProject, projectId) => (currentProject?.id === projectId ? currentProject : null)
);

export const selectCurrentProjectName = createSelector(selectCurrentProject, currentProject => currentProject?.name);

export const selectCurrentProjectEverRendered = createSelector(
  selectCurrentProject,
  currentProject => currentProject?.extra.hashHosted
);

export const selectCurrentProjectHostingId = createSelector(
  selectCurrentProject,
  currentProject => currentProject?.extra.hostingId
);

export const selectCurrentProjectFirstStream = createSelector(selectCurrentProject, currentProject => {
  const streams = currentProject?.extra.streams || [];

  return streams[0];
});

export const selectIsCurrentProjectPublished = createSelector(selectVideoData, manifest => !!manifest?.sources.length);
