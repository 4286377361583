import { AnimatronProject } from 'project';

const isDraft = ({ extra, version, modified, isDraft }: AnimatronProject) => {
  if (isDraft !== undefined) return isDraft;

  const isEverHosted = extra && !!extra.hostingId;
  const isNewProject = modified >= new Date('January 5, 2020').getTime();
  const isSameHash = extra && version === (extra.hashVersion || 0) && extra.hash === extra.hashHosted;

  return !(isEverHosted && isSameHash) && isNewProject;
};

export default isDraft;
