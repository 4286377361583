import * as analytic from '@anm/analytic';
import { ChangeSubscriptionResponse } from '@anm/api/modules/subscription';
import isServer from '@anm/helpers/is/isServer';
import roundPrice from '@anm/helpers/pricing/roundPrice';
import { cancel, fork, select, take } from '@anm/helpers/saga/effects';
import { takeType } from '@anm/helpers/saga/typesafe-actions';
import { Task } from 'redux-saga';

import { selectAppMetaProduct } from '../appMeta/selectors';
import { authActions, authSelectors } from '../auth';
import { filterActions, filterSelectors, TemplatesFilterData } from '../filter';
import { subscriptionActions } from '../subscription';
import { streamActions } from '../stream';
import { getSelectedFormat, templateSelectors } from '../template';
import { userActions, userSelectors } from '../user';
import { selectVideoFormats } from '../video';
import { trackIntercomStreamCreate } from '@anm/helpers/intercom/events';

function* trackAuth() {
  const { method, provider } = yield* select(authSelectors.selectProviderAndMethod);

  const userProfile = yield* select(userSelectors.selectUserProfile);
  if (!userProfile) return;

  const appMetaProduct = yield* select(selectAppMetaProduct);

  if (method === 'signup' && provider) {
    analytic.trackSignUpEvent({
      provider,
      userProfile,
      product: appMetaProduct
    });
  }

  if (method === 'login') {
    analytic.trackLogin({
      userProfile,
      product: appMetaProduct
    });
  }
}

function* watchUpdateUser() {
  while (true) {
    yield* take(takeType(authActions.authAsync.success));
    yield* take(takeType(userActions.updateAsync.success));

    yield* fork(trackAuth);
  }
}

function* addTransaction({ displayName, billingPeriod, totalAmount }: ChangeSubscriptionResponse) {
  const subscriptionName = `${displayName} - ${billingPeriod}`;
  const price = roundPrice(totalAmount);
  const useProfile = yield* select(userSelectors.selectUserProfile);

  price && useProfile && analytic.addTransaction(price, useProfile?.userId, subscriptionName);
}

function* watchChangeSubscription() {
  while (true) {
    const { payload }: ReturnType<typeof subscriptionActions.changeSubscriptionAsync.success> = yield take(
      takeType(subscriptionActions.changeSubscriptionAsync.success)
    );

    yield* fork(addTransaction, payload);
  }
}

let prevFilters: TemplatesFilterData = {};
function* trackFilters() {
  if (isServer()) return;

  const newFilters = yield* select(filterSelectors.selectSavedFilters);
  const videoFormats = yield* select(selectVideoFormats);
  const templateCount = yield* select(templateSelectors.selectTemplateListCount);

  const { name, aspects } = newFilters;

  const canTrackSearch = name !== prevFilters.name && !!name;
  const canTrackAspects = !!aspects && aspects !== prevFilters.aspects!;

  canTrackSearch && analytic.trackTemplatesSearch(name!, templateCount);

  if (canTrackAspects) {
    const format = getSelectedFormat(aspects!, videoFormats);

    format && analytic.trackTemplatesFilterAspectRatio(format);
  }
  prevFilters = newFilters;
}

let lastTask: Task;
function* watchTrackFilterEvents() {
  while (true) {
    yield* take(filterActions.updateSavedFilters);

    if (lastTask) {
      cancel(lastTask);
    }

    lastTask = yield fork(trackFilters);
  }
}

function* watchCreateStream() {
  while (true) {
    const { payload } = yield* take(streamActions.createStreamAsync.success);
    const destinations = payload.destinations.length;

    trackIntercomStreamCreate(payload.kind);

    analytic.trackCreateStream({ kind: payload.kind, destinationsCount: destinations, schedule: payload.schedule });
  }
}

const analyticsWatchers = () => [
  fork(watchUpdateUser),
  fork(watchCreateStream),
  fork(watchTrackFilterEvents),
  fork(watchChangeSubscription)
];

export default analyticsWatchers;
