import { getType } from '@anm/helpers/saga/typesafe-actions';
import { Reducer } from 'redux';

import { projectActions } from '..';
import { ProjectActions } from '../types';

import { initialProjectsState } from './index';

const { fetchProjectAsync, clearCurrentProject } = projectActions;

const projectReducer: Reducer<typeof initialProjectsState, ProjectActions> = (state = initialProjectsState, action) => {
  switch (action.type) {
    case getType(clearCurrentProject): {
      return {
        ...state,
        currentProject: { ...initialProjectsState.currentProject }
      };
    }
    case getType(fetchProjectAsync.request): {
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          isError: false,
          isPending: true
        }
      };
    }
    case getType(fetchProjectAsync.success): {
      const project = action.payload;

      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          data: project,
          isPending: false,
          embedStatus: !!project.embeddedAt
        }
      };
    }

    case getType(fetchProjectAsync.failure): {
      const { payload: newError } = action;
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          isError: true,
          error: newError,
          isPending: false
        }
      };
    }

    default:
      return state;
  }
};

export default projectReducer;
