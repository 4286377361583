import { getType } from '@anm/helpers/saga/typesafe-actions';
import { Reducer } from 'redux';
import { ProjectStreamsState } from '../types';
import { streamActions, StreamActions } from '..';

const initialState: ProjectStreamsState = {
  data: null,
  isError: false,
  isPending: false
};

const projectStreamsReducers: Reducer<ProjectStreamsState, StreamActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(streamActions.fetchStreamsByIdsAsync.request):
      return {
        ...state,
        isPending: true
      };
    case getType(streamActions.fetchStreamsByIdsAsync.success):
      return {
        ...state,
        data: action.payload,
        isPending: false
      };
    case getType(streamActions.fetchStreamsByIdsAsync.failure):
      return {
        ...state,
        isPending: false,
        isError: true,
        error: action.payload
      };

    case getType(streamActions.cleanProjectStreams):
      return initialState;

    default:
      return state;
  }
};

export default projectStreamsReducers;
