import asyncEntityHandlers from '@anm/helpers/redux/asyncEntityHandlers';
import pickRequestFailure from '@anm/helpers/redux/pickRequestFailure';
import reduceFactory from '@anm/helpers/redux/reduceFactory';
import { getType } from '@anm/helpers/saga/typesafe-actions';
import { Reducer } from 'redux';
import { LiveStream } from 'types/stream';

import { streamActions, StreamActions } from '..';
import { StreamsListState } from '../types';

const commonInitialState = {
  data: null,
  params: null,
  isError: false,
  isPending: false
};

const initialCreateStreamState = {
  stream: null,
  error: null,
  ...commonInitialState
};

export const DEFAULT_PAGE_LIMIT = 20;

const listCommonInitProps = {
  ...commonInitialState,
  list: [],
  limit: DEFAULT_PAGE_LIMIT,
  offset: 0,
  count: 0,
  params: null
};
const emptyStatusInitProps = {
  ...commonInitialState,
  isStreamEmpty: null
};

export const initialState: StreamsListState = {
  moveStream: commonInitialState,
  listData: listCommonInitProps,
  emptyStatus: emptyStatusInitProps,
  changeKind: commonInitialState,
  editStream: commonInitialState,
  stopStream: commonInitialState,
  deleteStream: commonInitialState,
  createStream: initialCreateStreamState,
  duplicateStream: listCommonInitProps,
  editDestinationMeta: commonInitialState,
  deleteRecording: commonInitialState
};

const streamsListReducer: Reducer<StreamsListState, StreamActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(streamActions.fetchStreamsListAsync.request):
      return {
        ...state,
        listData: {
          ...state.listData,
          params: action.payload || null,
          isPending: true,
          isError: false
        }
      };
    case getType(streamActions.fetchStreamsListAsync.success):
      const { params: listParams } = state.listData;
      const offset = listParams?.offset || 0;
      const limit = listParams?.limit || DEFAULT_PAGE_LIMIT;

      return {
        ...state,
        listData: {
          ...state.listData,
          limit,
          isPending: false,
          offset: offset + limit,
          list: action.payload
        }
      };
    case getType(streamActions.createStreamAsync.success):
      const newList = state.listData.list
        ? [{ ...action.payload, updated: action.payload.added }, ...state.listData.list]
        : [action.payload];

      return {
        ...state,
        listData: {
          ...state.listData,
          list: newList
        },
        createStream: {
          ...state.createStream,
          stream: action.payload,
          isPending: false,
          isError: false
        }
      };
    case getType(streamActions.addCreateStreamError):
    case getType(streamActions.clearCreatedStream):
      return {
        ...state,
        createStream: initialCreateStreamState
      };
    case getType(streamActions.editStreamAsync.success):
      const updatedDestinationList = [
        action.payload,
        ...state.listData.list.filter(s => s.streamId !== action.payload.streamId)
      ];

      return {
        ...state,
        listData: {
          ...state.listData,
          list: updatedDestinationList
        },
        editStream: {
          ...state.editStream,
          isPending: false
        },
        changeKind: commonInitialState
      };
    case getType(streamActions.editStreamAsync.failure):
      return {
        ...state,
        editStream: {
          ...state.editStream,
          isError: true,
          isPending: false,
          error: action.payload
        },
        changeKind: commonInitialState
      };
    case getType(streamActions.deleteStreamAsync.success):
      const listAfterDeleteStream = state.listData.list?.filter(
        s => s.streamId !== state.deleteStream.params?.streamId
      ) as LiveStream[];

      return {
        ...state,
        listData: {
          ...state.listData,
          list: listAfterDeleteStream
        },
        deleteStream: {
          ...state.deleteStream,
          isPending: false
        }
      };
    case getType(streamActions.updateDestinationMetaEntity):
      return {
        ...state,
        editDestinationMeta: {
          ...state.editDestinationMeta,
          ...action.payload
        }
      };
    case getType(streamActions.editStreamDestinationMetaAsync.success):
      const params = state.editDestinationMeta.params;

      const updatedStream = action.payload;
      const updatedList = state.listData.list?.filter(s => s.streamId !== params?.streamId) || [];

      return {
        ...state,
        listData: {
          ...state.listData,
          list: [...updatedList, updatedStream]
        },
        editDestinationMeta: {
          ...state.editDestinationMeta,
          isError: false,
          isPending: false
        }
      };
    case getType(streamActions.cleanStreamKind):
      return {
        ...state,
        changeKind: commonInitialState
      };
    case getType(streamActions.changeStreamKindAsync.success):
      const changeKindParams = state.changeKind.params!;

      const updateStream = state.listData.list.find(s => s.streamId === changeKindParams.streamId)!;
      const changeKindList = state.listData.list.filter(s => s.streamId !== changeKindParams.streamId);

      const streamWithKind = { ...updateStream, kind: action.payload.kind };

      return {
        ...state,
        listData: {
          ...state.listData,
          list: [streamWithKind, ...changeKindList]
        },
        changeKind: {
          ...state.changeKind,
          isError: false,
          isPending: false,
          data: action.payload
        }
      };
    case getType(streamActions.stopStreamAsync.success):
      const stoppedStreamId = state.stopStream.params as string;

      return {
        ...state,
        listData: {
          ...state.listData,
          list: state.listData.list.filter(s => s.streamId !== stoppedStreamId)
        },
        stopStream: {
          ...state.stopStream,
          isPending: false
        }
      };
    case getType(streamActions.checkStreamsEmptyAsync.success): {
      const streams = action.payload;
      return {
        ...state,
        emptyStatus: {
          ...state.emptyStatus,
          isStreamEmpty: (streams || []).length <= 0,
          isPending: false
        }
      };
    }
    case getType(streamActions.deleteRecordingAsync.success):
      return {
        ...state,
        listData: {
          ...state.listData,
          list: state.listData.list.filter(s => s.streamId !== state.deleteRecording.params?.streamId)
        },
        deleteStream: {
          ...state.deleteStream,
          isPending: false
        }
      };

    default:
      return reduceFactory(initialState, {
        ...asyncEntityHandlers<StreamsListState>(
          pickRequestFailure(streamActions.checkStreamsEmptyAsync),
          'emptyStatus'
        ),
        ...asyncEntityHandlers<StreamsListState>({ failure: streamActions.fetchStreamsListAsync.failure }, 'listData'),
        ...asyncEntityHandlers<StreamsListState>(pickRequestFailure(streamActions.createStreamAsync), 'createStream'),
        ...asyncEntityHandlers<StreamsListState>(pickRequestFailure(streamActions.stopStreamAsync), 'stopStream'),
        ...asyncEntityHandlers<StreamsListState>(pickRequestFailure(streamActions.deleteStreamAsync), 'deleteStream'),
        ...asyncEntityHandlers<StreamsListState>({ request: streamActions.editStreamAsync.request }, 'editStream'),
        ...asyncEntityHandlers<StreamsListState>(streamActions.moveStreamToOtherAsync, 'moveStream'),
        ...asyncEntityHandlers<StreamsListState>(pickRequestFailure(streamActions.changeStreamKindAsync), 'changeKind'),
        ...asyncEntityHandlers<StreamsListState>(
          pickRequestFailure(streamActions.deleteRecordingAsync),
          'deleteRecording'
        ),
        ...asyncEntityHandlers<StreamsListState>(streamActions.duplicateStreamAsync, 'duplicateStream'),
        ...asyncEntityHandlers<StreamsListState>(
          pickRequestFailure(streamActions.editStreamDestinationMetaAsync),
          'editDestinationMeta'
        )
      })(state, action);
  }
};

export default streamsListReducer;
