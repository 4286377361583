import api from '@anm/api';
import { GetProjectProps, ProjectListParams } from '@anm/api/modules/projects';
import asyncEntity from '@anm/helpers/redux/asyncEntity';
import { call, cancel, fork, select, take } from '@anm/helpers/saga/effects';
import { takeType } from '@anm/helpers/saga/typesafe-actions';
import { Task } from '@redux-saga/types';

import { appMetaSelectors } from '../appMeta';

import { projectActions } from '.';

const fetchProjectsAsync = asyncEntity(projectActions.fetchProjectsAsync, (params: ProjectListParams) =>
  api().projects.getProjectList(params)
);
const fetchSearchProjectsAsync = asyncEntity(projectActions.fetchSearchProjectsAsync, (params: ProjectListParams) =>
  api().projects.getProjectList(params)
);

const fetchProjectAsync = asyncEntity(projectActions.fetchProjectAsync, (params: GetProjectProps) =>
  api().projects.getProject(params)
);

function* watchFetchProjectsCount() {
  while (true) {
    yield take(takeType(projectActions.fetchProjectsCount));
    const workspace = yield* select(appMetaSelectors.selectWorkspace);

    yield call(fetchProjectsAsync, { workspace, limit: 0, product: 'WAVE', offset: 0 });
  }
}

let searchProjectLastTask: Task;
function* watchSearchProjects() {
  while (true) {
    const { payload: keyword } = yield* take(takeType(projectActions.searchProjects));
    const workspace = yield* select(appMetaSelectors.selectWorkspace);

    if (searchProjectLastTask) {
      yield* cancel(searchProjectLastTask);
    }

    searchProjectLastTask = yield* fork(fetchSearchProjectsAsync, {
      workspace,
      limit: 1000 * 1000,
      product: 'WAVE',
      offset: 0,
      title_keywords: keyword
    });
  }
}

function* watchCancelSearchProjects() {
  while (true) {
    yield* take(takeType(projectActions.cancelSearchProjects));
    if (searchProjectLastTask) {
      yield* cancel(searchProjectLastTask);
    }
  }
}

function* watchFetchProject() {
  while (true) {
    const { payload: projectId }: ReturnType<typeof projectActions.fetchProject> = yield take(
      takeType(projectActions.fetchProject)
    );
    const workspace = yield* select(appMetaSelectors.selectWorkspace);

    yield* call(fetchProjectAsync, { workspace, id: projectId });
  }
}

const projectSagaWatchers = () => [
  call(watchFetchProject),
  call(watchFetchProjectsCount),
  call(watchSearchProjects),
  call(watchCancelSearchProjects)
];

export default projectSagaWatchers;
