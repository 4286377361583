import { isChrome, isSafari } from '@anm/helpers/is/browserCheckers';
import isMobileOrIPad from '@anm/helpers/is/isMobileOrIPad';
import isServer from '@anm/helpers/is/isServer';
import { isDev } from '@anm/shared/mode';

declare global {
  interface Window {
    chrome?: boolean;
  }
}

const open = (href: string) => (window.location.href = window.location.origin + href);

const streamBrowserAccess = (openLink: boolean | typeof open = open) => {
  if (isServer() || isDev) return;

  const isMobileOrIpadSafari = isMobileOrIPad() && (isSafari() || isChrome());
  const isSupportedBrowser = isChrome() || isMobileOrIpadSafari;

  return isSupportedBrowser || (openLink && (openLink as typeof open)('/error-non-supported-browser'));
};

export default streamBrowserAccess;
