import { DestinationWithStreamMeta } from '@anm/components/stream/StreamItem';
import selectProps from '@anm/helpers/store/selectProps';
import getIsPastStream from '@anm/helpers/stream/getIsPastStream';
import { userDestinationsSelectors } from '@anm/store/modules/userDestinations';
import { createSelector } from 'reselect';
import { LiveStreamDestination } from 'types/stream';

import configs from '../../../config';
import { ApplicationState } from '../../types';

import { sortStreams } from './helpers';
import { DEFAULT_PAGE_LIMIT } from './reducer/streamsListReducer';

const { urls } = configs();

const selectStreamState = ({ stream }: ApplicationState) => stream;

const selectStreamListEntity = createSelector(selectStreamState, stream => stream.streamsList);

export const selectStreamsListParams = createSelector(selectStreamListEntity, streamList => streamList.listData.params);
export const selectMoveStreamPending = createSelector(
  selectStreamListEntity,
  streamList => streamList.moveStream.isPending
);
export const selectFullStreamsList = createSelector(selectStreamListEntity, streamList => streamList.listData.list);
export const selectIsStreamsEmpty = createSelector(
  selectStreamListEntity,
  streamList => streamList.emptyStatus.isStreamEmpty
);
export const selectStreamsList = createSelector(selectStreamListEntity, selectStreamsListParams, (streamList, params) =>
  streamList.listData.list?.slice(0, (params?.limit || DEFAULT_PAGE_LIMIT) - 1)
);
export const selectStreamsListIsPending = createSelector(
  selectStreamListEntity,
  streamList => streamList.listData.isPending
);
export const selectStreamsListIsError = createSelector(
  selectStreamListEntity,
  streamList => streamList.listData.isError
);

export const selectStreamById = createSelector(
  selectStreamsList,
  selectProps<string | undefined | null>(),
  (list, id) => list?.find(s => s.streamId === id)
);

export const selectUpcomingStreams = createSelector(selectStreamsList, streams =>
  sortStreams({
    streams: streams?.filter(s => s.state === 'SCHEDULED'),
    getValue: s => s.updated
  })
);

export const selectInProgressStreams = createSelector(selectStreamsList, streams =>
  sortStreams({
    streams: streams?.filter(s => ['INPROGRESS', 'PAUSED'].includes(s.state)),
    getValue: s => s.meta.startedAt as string
  })
);

export const selectPastStreams = createSelector(selectStreamsList, streams =>
  sortStreams({
    streams: streams?.filter(s => getIsPastStream(s.state)),
    getValue: s => s.meta.endedAt as string
  })
);

export const selectStreamDestinations = createSelector(
  userDestinationsSelectors.selectEnabledDestinations,
  selectProps<LiveStreamDestination[]>(),
  (socialDestinations, streamDestinations) =>
    streamDestinations?.map(d => {
      const dest = socialDestinations?.find(s => s.id === d.destination_id);
      const kind = dest?.kind || d.kind;
      const avatar = `${urls.streamingLiveApi}dest-avatar?userId=${d.user_id}&destinationId=${d.destination_id}`;

      return {
        ...dest,
        ...d,
        id: dest?.id || d.destination_id,
        network: dest?.network || d.network,
        avatar: d.socialInfo?.avatar || avatar,
        ...(kind && { kind }),
        request: d?.request,
        response: d?.response
      } as DestinationWithStreamMeta;
    }) || []
);
export const selectDeleteStreamEntity = createSelector(selectStreamListEntity, streamList => streamList.deleteStream);

export const selectCreateStreamEntity = createSelector(selectStreamListEntity, streamList => streamList.createStream);
export const selectCreateStreamPending = createSelector(
  selectCreateStreamEntity,
  createStream => createStream.isPending
);
export const selectCreatedStreamRoomID = createSelector(
  selectCreateStreamEntity,
  createStream => createStream.stream?.streamId
);
export const selectCreateStreamParams = createSelector(selectCreateStreamEntity, createStream => createStream.params);

export const selectEditStreamEntity = createSelector(selectStreamListEntity, streamList => streamList.editStream);
export const selectEditStreamIsPending = createSelector(selectEditStreamEntity, editStream => editStream.isPending);

export const selectDeleteStreamIsPending = createSelector(
  selectDeleteStreamEntity,
  deleteStream => deleteStream.isPending
);

export const selectEditStreamDestinationMetaEntity = createSelector(
  selectStreamListEntity,
  streamList => streamList.editDestinationMeta
);
export const selectIsPendingEditDestinationMeta = createSelector(
  selectEditStreamDestinationMetaEntity,
  editDestinationMeta => editDestinationMeta.isPending
);

export const selectChangeStreamKindEntity = createSelector(selectStreamListEntity, streamList => streamList.changeKind);

export const selectChangeStreamKindResponse = createSelector(
  selectChangeStreamKindEntity,
  changeKind => changeKind.data
);

export const selectChangeStreamKindIsPending = createSelector(
  selectChangeStreamKindEntity,
  changeKind => changeKind.isPending
);

export const selectIsPendingDuplicateStream = createSelector(
  selectStreamListEntity,
  streamList => streamList.duplicateStream.isPending
);

export const selectStream = createSelector(selectStreamState, streamState => streamState.stream.stream.data);

export const selectStreamId = createSelector(selectStream, stream => stream?.streamId);

export const selectStreamUploadId = createSelector(selectStream, stream => stream?.meta.uploadId);

export const selectProjectStreamsEntity = createSelector(selectStreamState, state => state.projectStreams);

export const selectRoomSettings = createSelector(selectStreamState, state => state.roomSettings.data);
export const selectRoomSettingsParams = createSelector(selectStreamState, state => state.roomSettings.data);

export const selectGuestDestinationsParams = createSelector(
  selectStreamState,
  state => state.guestsDestinations.params
);
export const selectGuestDestinations = createSelector(
  selectStreamState,
  selectGuestDestinationsParams,
  selectProps<string | undefined>(),
  (state, guestsStreamId, streamId) => (guestsStreamId === streamId ? state.guestsDestinations.data : null)
);

export const selectSaveSettingsParams = createSelector(
  selectStreamState,
  state => state.roomSettings.saveSettings.params
);

export const selectSaveSettingsData = createSelector(selectStreamState, state => state.roomSettings.saveSettings.data);
