import { getType } from '@anm/helpers/saga/typesafe-actions';
import { Reducer } from 'redux';

import {
  cancelSearchProjects,
  changeSearchOpen,
  clearCurrentProject,
  decrementProjectCount,
  fetchProjectsAsync,
  fetchProjectAsync,
  fetchSearchProjectsAsync,
  incrementProjectCount,
  searchProjects,
  toggleProjectStreamModal
} from '../actions';
import { ProjectActions, ProjectState } from '../types';

import projectListReducer from './projectListReducer';
import projectReducer from './projectReducer';
import searchProjectListReducer from './searchProjectListReducer';

export const initialProjectsState: ProjectState = {
  searchProjects: {
    search: null,
    isSearchOpen: null,
    list: [],
    count: 0,
    limit: 10,
    offset: 0,
    isError: false,
    isPending: false,
    isProjectsEmpty: null
  },
  projectItems: {
    list: [],
    count: 0,
    limit: 10,
    offset: 0,
    isError: false,
    isPending: false,
    wasFetched: false,
    isProjectsEmpty: null,
    isStreamModalOpen: false
  },
  currentProject: {
    data: null,
    isError: false,
    isPending: false
  }
};

const videoProjectReducer: Reducer<ProjectState, ProjectActions> = (state = initialProjectsState, action) => {
  switch (action.type) {
    case getType(fetchProjectsAsync.request):
    case getType(fetchProjectsAsync.success):
    case getType(fetchProjectsAsync.failure):
    case getType(incrementProjectCount):
    case getType(decrementProjectCount):
    case getType(toggleProjectStreamModal):
      return projectListReducer(state, action);

    case getType(fetchSearchProjectsAsync.request):
    case getType(fetchSearchProjectsAsync.success):
    case getType(fetchSearchProjectsAsync.failure):
    case getType(searchProjects):
    case getType(changeSearchOpen):
    case getType(cancelSearchProjects):
      return searchProjectListReducer(state, action);

    case getType(fetchProjectAsync.request):
    case getType(fetchProjectAsync.success):
    case getType(fetchProjectAsync.failure):
    case getType(clearCurrentProject):
      return projectReducer(state, action);

    default:
      return state;
  }
};

export default videoProjectReducer;
