import asyncEntityHandlers from '@anm/helpers/redux/asyncEntityHandlers';
import pickRequestFailure from '@anm/helpers/redux/pickRequestFailure';
import reduceFactory from '@anm/helpers/redux/reduceFactory';
import { getType } from '@anm/helpers/saga/typesafe-actions';
import { Reducer } from 'redux';
import { DestinationObject } from 'types/stream';

import { userDestinationsActions, UserDestinationsActions } from '..';
import { UserDestinationsList } from '../types';

const defaultDeleteDestinationState = {
  isError: false,
  isPending: false,
  params: null
};

const initialState: UserDestinationsList = {
  list: null,
  params: null,
  isError: false,
  isPending: false,
  canCheckErrors: false,
  toggleDestinationPending: false,
  deleteDestination: defaultDeleteDestinationState,
  deleteGuestsDestinations: defaultDeleteDestinationState
};

const userDestinationsReducer: Reducer<UserDestinationsList, UserDestinationsActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case getType(userDestinationsActions.cleanDestinations):
      return initialState;
    case getType(userDestinationsActions.fetchDestinationsListAsync.request):
      return {
        ...state,
        params: {
          isFetched: false
        },
        canCheckErrors: false,
        isPending: true
      };
    case getType(userDestinationsActions.checkDestinationsAsync.request):
      return state;
    case getType(userDestinationsActions.fetchDestinationsListAsync.success):
      return {
        ...state,
        params: {
          isFetched: true
        },
        isPending: false,
        list: action.payload
      };
    case getType(userDestinationsActions.checkDestinationsAsync.success):
      return {
        ...state,
        list: action.payload
      };

    case getType(userDestinationsActions.deleteSocialDestinationAsync.request): {
      return {
        ...state,
        deleteDestination: {
          ...state.deleteDestination,
          params: {
            ...action.payload,
            notify: typeof action.payload.notify === 'undefined' ? true : false
          }
        }
      };
    }

    case getType(userDestinationsActions.deleteSocialDestinationAsync.success): {
      return {
        ...state,
        list: action.payload,
        deleteDestination: {
          ...state.deleteDestination,
          isError: false,
          isPending: false
        }
      };
    }

    case getType(userDestinationsActions.deleteSocialDestinationAsync.failure): {
      return {
        ...state,
        deleteDestination: {
          ...state.deleteDestination,
          isError: true,
          isPending: false
        }
      };
    }
    case getType(userDestinationsActions.toggleSocialDestinationsAsync.request):
      return {
        ...state,
        toggleDestinationPending: true
      };
    case getType(userDestinationsActions.toggleSocialDestinationsAsync.success): {
      const response = action.payload;
      const id = Object.keys(response)[0];
      const isError = typeof response[id] === 'string';

      if (isError) {
        const updatedlist: DestinationObject = {
          ...state.list,
          [id]: {
            ...state.list?.[id]!,
            error: response[id] as any
          }
        };

        return {
          ...state,
          list: updatedlist,
          toggleDestinationPending: false
        };
      }

      return {
        ...state,
        list: action.payload,
        canCheckErrors: true,
        toggleDestinationPending: false
      };
    }

    case getType(userDestinationsActions.toggleSocialDestinationsAsync.failure): {
      return {
        ...state,
        isError: true,
        error: action.payload,
        toggleDestinationPending: false
      };
    }

    case getType(userDestinationsActions.clearDeleteUserDestinations): {
      return {
        ...state,
        deleteDestination: initialState.deleteDestination
      };
    }

    default:
      return reduceFactory(initialState, {
        ...asyncEntityHandlers<UserDestinationsList>(
          pickRequestFailure(userDestinationsActions.fetchDestinationsListAsync)
        ),
        ...asyncEntityHandlers<UserDestinationsList>(
          pickRequestFailure(userDestinationsActions.checkDestinationsAsync)
        ),
        ...asyncEntityHandlers<UserDestinationsList>(
          pickRequestFailure(userDestinationsActions.addRTMPDestinationAsync)
        ),
        ...asyncEntityHandlers<UserDestinationsList>(
          userDestinationsActions.deleteGuestDestinationsAsync,
          'deleteGuestsDestinations',
          true
        )
      })(state, action);
  }
};

export default userDestinationsReducer;
