import { ApiError, ResponseState } from '@anm/api';
import { MoveStreamToWorkspaceParams, SaveRoomSettingsParams } from '@anm/api/modules/stream';
import {
  ChangeKindParams,
  CreateStreamRequest,
  DeleteStreamProps,
  DuplicateStreamParams,
  EditStreamMetaProps,
  EditStreamProps,
  StreamListFilterParams,
  UpdateStreamResolution
} from '@anm/api/modules/stream';
import { createAction, createAsyncAction } from '@anm/helpers/saga/typesafe-actions';
import { RoomSettings } from '@anm/shared/types/room';
import { DestinationObject, LiveStream } from 'types/stream';

import { SaveCanAddGuestDestination } from './types';

export const fetchStreamsNextPage = createAction('wave.video/stream/FETCH_NEXT_PAGE')();
export const fetchStreamsPrevPage = createAction('wave.video/stream/FETCH_PREV_PAGE')();
export const reFetchStreamsList = createAction('wave.video/stream/RE_FETCH_LIST')();

export const checkStreamsEmpty = createAction('wave.video/stream/CHECK_STREAMS_EMPTY')();

export const checkStreamsEmptyAsync = createAsyncAction(
  'wave.video/stream/CHECK_STREAMS_EMPTY_REQUEST',
  'wave.video/stream/CHECK_STREAMS_EMPTY_SUCCESS',
  'wave.video/stream/CHECK_STREAMS_EMPTY_FAILURE'
)<undefined, LiveStream[], ApiError>();

export const fetchStreamsList = createAction('wave.video/stream/FETCH_STREAMS_LIST')<
  StreamListFilterParams | undefined
>();

export const fetchStreamsListAsync = createAsyncAction(
  'wave.video/stream/FETCH_STREAMS_LIST_REQUEST',
  'wave.video/stream/FETCH_STREAMS_LIST_SUCCESS',
  'wave.video/stream/FETCH_STREAMS_LIST_FAILURE'
)<StreamListFilterParams | undefined, LiveStream[], ApiError>();

export const createStream = createAction('wave.video/stream/CREATE_STREAM')<CreateStreamRequest>();

export const createStreamAsync = createAsyncAction(
  'wave.video/stream/CREATE_STREAM_REQUEST',
  'wave.video/stream/CREATE_STREAM_SUCCESS',
  'wave.video/stream/CREATE_STREAM_FAILURE'
)<CreateStreamRequest, LiveStream, ApiError>();

export const clearCreatedStream = createAction('wave.video/stream/CLEAR_CREATED_STREAM')();

export const clearDeleteStreamDestinations = createAction('wave.video/stream/CLEAR_DELETE_STREAM_DESTINATIONS')();

export const deleteStream = createAction('wave.video/stream/DELETE_STREAM')<DeleteStreamProps>();

export const deleteStreamAsync = createAsyncAction(
  'wave.video/stream/DELETE_STREAM_REQUEST',
  'wave.video/stream/DELETE_STREAM_SUCCESS',
  'wave.video/stream/DELETE_STREAM_FAILURE'
)<DeleteStreamProps, LiveStream, ApiError>();

export const addCreateStreamError = createAction('wave.video/stream/ADD_CREATE_STREAM_ERROR')<ApiError>();

export const editStream = createAction('wave.video/stream/EDIT_STREAM')<EditStreamProps>();

export const editStreamAsync = createAsyncAction(
  'wave.video/stream/EDIT_STREAM_REQUEST',
  'wave.video/stream/EDIT_STREAM_SUCCESS',
  'wave.video/stream/EDIT_STREAM_FAILURE'
)<EditStreamProps, LiveStream, ApiError>();

export const editStreamDestinationMeta = createAction('wave.video/stream/EDIT_STREAM__META')<EditStreamMetaProps>();
export const editStreamDestinationMetaAsync = createAsyncAction(
  'wave.video/stream/EDIT_STREAM__META_REQUEST',
  'wave.video/stream/EDIT_STREAM__META_SUCCESS',
  'wave.video/stream/EDIT_STREAM__META_FAILURE'
)<EditStreamMetaProps, LiveStream, ApiError>();

export const changeStreamKind = createAction('wave.video/stream/CHANGE_STREAM_KIND')<ChangeKindParams>();
export const cleanStreamKind = createAction('wave.video/stream/CLEAN_CHANGE_STREAM_KIND')();

export const changeStreamKindAsync = createAsyncAction(
  'wave.video/stream/CHANGE_STREAM_KIND_REQUEST',
  'wave.video/stream/CHANGE_STREAM_KIND_SUCCESS',
  'wave.video/stream/CHANGE_STREAM_KIND_FAILURE'
)<ChangeKindParams, LiveStream, ApiError>();

export const updateDestinationMetaEntity = createAction('wave.video/stream/UPDATE_DESTINATION_META_ENTITY')<
  ResponseState & { isUploaded?: boolean }
>();

export const updateStreamResolution = createAction('wave.video/stream/UPDATE_STREAM_RESOLUTION')<
  UpdateStreamResolution
>();

export const updateStreamResolutionAsync = createAsyncAction(
  'wave.video/stream/UPDATE_STREAM_RESOLUTION_REQUEST',
  'wave.video/stream/UPDATE_STREAM_RESOLUTION_SUCCESS',
  'wave.video/stream/UPDATE_STREAM_RESOLUTION_FAILURE'
)<UpdateStreamResolution, void, ApiError>();

export const stopStream = createAction('wave.video/stream/STOP_STREAM')<string>();

export const stopStreamAsync = createAsyncAction(
  'wave.video/stream/STOP_STREAM_REQUEST',
  'wave.video/stream/STOP_STREAM_SUCCESS',
  'wave.video/stream/STOP_STREAM_FAILURE'
)<string, void, ApiError>();

export const checkStream = createAction('wave.video/stream/CHECK_STREAM')<EditStreamProps>();

export const checkStreamAsync = createAsyncAction(
  'wave.video/stream/CHECK_STREAM_REQUEST',
  'wave.video/stream/CHECK_STREAM_SUCCESS',
  'wave.video/stream/CHECK_STREAM_FAILURE'
)<EditStreamProps, LiveStream, ApiError>();

export const duplicateStream = createAction('wave.video/stream/DUPLICATE_STREAM')<DuplicateStreamParams>();

export const duplicateStreamAsync = createAsyncAction(
  'wave.video/stream/DUPLICATE_STREAM_REQUEST',
  'wave.video/stream/DUPLICATE_STREAM_SUCCESS',
  'wave.video/stream/DUPLICATE_STREAM_FAILURE'
)<CreateStreamRequest, string, ApiError>();

export const fetchStream = createAction('wave.video/stream/FETCH_STREAM')<string>();

export const fetchStreamAsync = createAsyncAction(
  'wave.video/stream/FETCH_STREAM_REQUEST',
  'wave.video/stream/FETCH_STREAM_SUCCESS',
  'wave.video/stream/FETCH_STREAM_FAILURE'
)<string, LiveStream, ApiError>();

export const clearStream = createAction('wave.video/stream/CLEAR_STREAM')();

export const fetchStreamsByIds = createAction('wave.video/stream/FETCH_STREAMS_BY_IDS')<string[]>();

export const fetchStreamsByIdsAsync = createAsyncAction(
  'wave.video/stream/FETCH_STREAMS_BY_IDS_REQUEST',
  'wave.video/stream/FETCH_STREAMS_BY_IDS_SUCCESS',
  'wave.video/stream/FETCH_STREAMS_BY_IDS_FAILURE'
)<string[], LiveStream[], ApiError>();

export const cleanProjectStreams = createAction('wave.video/stream/CLEAN_PROJECT_STREAMS')();

export const getRoomSettings = createAction('wave.video/stream/GET_ROOM_SETTINGS')<string>();
export const getRoomSettingsAsync = createAsyncAction(
  'wave.video/stream/GET_ROOM_SETTINGS_REQUEST',
  'wave.video/stream/GET_ROOM_SETTINGS_SUCCESS',
  'wave.video/stream/GET_ROOM_SETTINGS_FAILURE'
)<string, RoomSettings, ApiError>();

export const saveCanAddGuestsRoomSettings = createAction('wave.video/stream/SAVE_CAN_ADD_GUESTS_ROOM_SETTINGS')<
  SaveCanAddGuestDestination
>();

export const saveRoomSettingsAsync = createAsyncAction(
  'wave.video/stream/SAVE_ROOM_SETTINGS_REQUEST',
  'wave.video/stream/SAVE_ROOM_SETTINGS_SUCCESS',
  'wave.video/stream/SAVE_ROOM_SETTINGS_FAILURE'
)<SaveRoomSettingsParams, RoomSettings, ApiError>();

export const clearRoomSettings = createAction('wave.video/stream/CLEAR_ROOM_SETTINGS')();

export const fetchGuestsDestinations = createAction('wave.video/stream/FETCH_GUESTS_DESTINATIONS')<string>();
export const fetchGuestsDestinationsAsync = createAsyncAction(
  'wave.video/stream/FETCH_GUESTS_DESTINATIONS_REQUEST',
  'wave.video/stream/FETCH_GUESTS_DESTINATIONS_SUCCESS',
  'wave.video/stream/FETCH_GUESTS_DESTINATIONS_FAILURE'
)<string, Record<string, DestinationObject>, ApiError>();

export const deleteRecording = createAction('wave.video/stream/DELETE_RECORDING')<string>();
export const deleteRecordingAsync = createAsyncAction(
  'wave.video/stream/DELETE_RECORDING_REQUEST',
  'wave.video/stream/DELETE_RECORDING_SUCCESS',
  'wave.video/stream/DELETE_RECORDING_FAILURE'
)<DeleteStreamProps, void, ApiError>();

export const moveStreamToUser = createAction('wave.video/stream/MOVE_STREAM_TO_USER_WORKSPACE')<
  MoveStreamToWorkspaceParams
>();
export const moveStreamToTeam = createAction('wave.video/stream/MOVE_STREAM_TO_TEAM_WORKSPACE')<
  MoveStreamToWorkspaceParams
>();
export const moveStreamToOtherAsync = createAsyncAction(
  'wave.video/stream/MOVE_STREAM_TO_OTHER_WORKSPACE_REQUEST',
  'wave.video/stream/MOVE_STREAM_TO_OTHER_WORKSPACE_SUCCESS',
  'wave.video/stream/MOVE_STREAM_TO_OTHER_WORKSPACE_FAILURE'
)<MoveStreamToWorkspaceParams, MoveStreamToWorkspaceParams, ApiError>();
