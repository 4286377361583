import api from '@anm/api';
import {
  DuplicateRequestParams,
  GetStreamsByIdsParams,
  GetStreamParams,
  MoveStreamToWorkspaceParams,
  UpdateStreamResolution
} from '@anm/api/modules/stream';
import {
  ChangeKindParams,
  CreateStreamRequest,
  DeleteStreamProps,
  EditStreamProps,
  StreamListFilterParams
} from '@anm/api/modules/stream';
import asyncEntity from '@anm/helpers/redux/asyncEntity';
import { call, fork, put, select, take } from '@anm/helpers/saga/effects';
import { takeType } from '@anm/helpers/saga/typesafe-actions';
import getStreamErrorDescription from '@anm/helpers/stream/getStreamErrorDescription';
import streamBrowserAccess from '@anm/helpers/stream/streamBrowserAccess';
import { DefaultRoomSettings } from '@anm/shared/types/room';
import { userDestinationsActions, userDestinationsSelectors } from '@anm/store/modules/userDestinations';
import omitBy from 'lodash/fp/omitBy';
import { DestinationObject } from 'types/stream';

import configs from '../../../config';
import routes from '../../../routes';
import { appMetaSelectors } from '../appMeta';
import { appNotificationActions } from '../appNotifications';
import { userSelectors } from '../user';
import { userPrefsSelectors } from '../userprefs';

import { streamActions, streamSelectors } from '.';
import { getEditStreamError, getResolutionHeight } from './helpers';
import { DEFAULT_PAGE_LIMIT } from './reducer/streamsListReducer';

const { Router } = routes;
const { urls } = configs();

const createStreamRequest = asyncEntity(streamActions.createStreamAsync, (data: CreateStreamRequest) =>
  api().stream.createStream(data)
);

const moveStreamToTeamAsync = asyncEntity(streamActions.moveStreamToOtherAsync, (params: MoveStreamToWorkspaceParams) =>
  api().stream.moveStreamToTeam(params)
);

const moveStreamToUserAsync = asyncEntity(streamActions.moveStreamToOtherAsync, (params: MoveStreamToWorkspaceParams) =>
  api().stream.moveStreamToUser(params)
);

const startStream = async (streamId: string) => await api().stream.startStream({ streamId });

function* watchMoveStreamToTeam() {
  while (true) {
    const { payload: params } = yield* take(takeType(streamActions.moveStreamToTeam));
    yield* fork(moveStreamToTeamAsync, params, params);
  }
}

function* watchMoveStreamToUser() {
  while (true) {
    const { payload: params } = yield* take(takeType(streamActions.moveStreamToUser));
    yield* fork(moveStreamToUserAsync, params, params);
  }
}

function* watchCreateStream() {
  while (true) {
    const {
      payload: { isOpenStudio: _, isDuplicate, canGuestAddDestination, ...requestData }
    }: ReturnType<typeof streamActions.createStream> = yield* take(takeType(streamActions.createStream));
    const workspace = yield* select(appMetaSelectors.selectWorkspace);

    const params = { workspace, isDuplicate, canGuestAddDestination, ...requestData };

    yield* call(createStreamRequest, { workspace, ...requestData }, params);
  }
}

function* getStreamResolution() {
  const resolutionFromPrefs = yield* select(userPrefsSelectors.selectPreferredStreamResolution);
  const maxStreamingResolution = yield* select(userSelectors.selectMaxStreamingResolution);
  const height = getResolutionHeight(resolutionFromPrefs, maxStreamingResolution);

  const streamResolution = {
    height,
    width: height * (16 / 9)
  };

  return streamResolution;
}

function* watchCreateStreamSuccess() {
  while (true) {
    const { payload }: ReturnType<typeof streamActions.createStreamAsync.success> = yield* take(
      takeType(streamActions.createStreamAsync.success)
    );
    const params = yield* select(streamSelectors.selectCreateStreamParams);

    const { kind, meta, streamId, error, schedule } = payload;
    const canOpenStudio = (!!params?.isOpenStudio || kind === 'RECORD') && !params?.isDuplicate;
    const isHostedStream = kind === 'HOSTED';
    const canStartStream = isHostedStream && !schedule;

    if (canStartStream) {
      yield* call(startStream, streamId);
    }

    if (isHostedStream) {
      // when  create stream from customization
      const tab = schedule ? 'upcoming' : 'in-progress';
      Router.pushRoute('lives', { tab });
    } else if (canOpenStudio) {
      // when  create stream from /live or /recordings
      streamBrowserAccess(false) && window.open(`${urls.streamingStudio}${meta.roomId}`);
    }

    yield* put(
      streamActions.saveCanAddGuestsRoomSettings({
        roomId: payload.meta.roomId,
        canGuestAddDestination: !!params?.canGuestAddDestination
      })
    );

    const streamResolution = yield* getStreamResolution();
    yield* put(streamActions.updateStreamResolution({ streamId, ...streamResolution }));

    const listParams = yield* select(streamSelectors.selectStreamsListParams);
    yield* put(streamActions.fetchStreamsList({ offset: 0, state: listParams?.state || 'Scheduled' }));

    const streamKind = payload.kind === 'RECORD' ? 'Recording' : 'Stream';

    yield* put(
      appNotificationActions.notify({
        type: 'success',
        description: `${streamKind} has been created`
      })
    );

    if (error) {
      const destinations = yield* select(userDestinationsSelectors.selectDestinationsObject);
      const destinationErrors = getStreamErrorDescription(destinations, error?.message);

      yield* put(
        appNotificationActions.notify({
          type: 'error',
          description: `<b>Failed to schedule event on these destinations.</b>${destinationErrors}`
        })
      );
    }
  }
}

function* watchCreateStreamFailure() {
  while (true) {
    const { payload }: ReturnType<typeof streamActions.createStreamAsync.failure> = yield* take(
      takeType(streamActions.createStreamAsync.failure)
    );

    const destinations = yield* select(userDestinationsSelectors.selectDestinationsObject);
    const errorDescription = getStreamErrorDescription(destinations, payload.data);

    yield* put(
      appNotificationActions.notify({
        type: 'error',
        description: `<b>Failed to schedule a new stream.</b> ${errorDescription}`
      })
    );
  }
}

const updateStreamResolutionRequest = asyncEntity(
  streamActions.updateStreamResolutionAsync,
  (params: UpdateStreamResolution) => api().stream.updateStreamResolution(params)
);

function* watchUpdateStreamResolution() {
  while (true) {
    const { payload }: ReturnType<typeof streamActions.updateStreamResolution> = yield* take(
      takeType(streamActions.updateStreamResolution)
    );

    yield* fork(updateStreamResolutionRequest, payload);
  }
}

function* watchUpdateStreamResolutionFailure() {
  while (true) {
    yield* take(takeType(streamActions.updateStreamResolutionAsync.failure));

    yield* put(
      appNotificationActions.notify({
        type: 'error',
        description: `Could not update the stream's resolution`
      })
    );
  }
}

const fetchStreamsListRequest = asyncEntity(streamActions.fetchStreamsListAsync, (params: StreamListFilterParams) =>
  api().stream.getStreams(params)
);

function* watchFetchStreamsList() {
  while (true) {
    const { payload } = yield* take(takeType(streamActions.fetchStreamsList));
    const workspace = yield* select(appMetaSelectors.selectWorkspace);

    yield* fork(fetchStreamsListRequest, { workspace, limit: DEFAULT_PAGE_LIMIT + 1, ...payload });
  }
}

const checkStreamsEmptyRequest = asyncEntity(streamActions.checkStreamsEmptyAsync, () =>
  api().stream.getStreams({ limit: 1, offset: 0 })
);

function* watchStreamsEmptyCheck() {
  while (true) {
    yield* take(takeType(streamActions.checkStreamsEmpty));
    yield* fork(checkStreamsEmptyRequest);
  }
}

function* watchFetchNextStreamPage() {
  while (true) {
    yield* take(takeType(streamActions.fetchStreamsNextPage));

    const params = (yield* select(streamSelectors.selectStreamsListParams)) as StreamListFilterParams;

    if (!params?.limit) return;

    const oldOffset = params?.offset || 0;
    const limit = params.limit - 1 || DEFAULT_PAGE_LIMIT;

    const requestParams = {
      ...params,
      offset: oldOffset + limit
    };

    yield* fork(fetchStreamsListRequest, requestParams);
  }
}

function* watchFetchPrevStreamPage() {
  while (true) {
    yield* take(takeType(streamActions.fetchStreamsPrevPage));

    const params = (yield* select(streamSelectors.selectStreamsListParams)) as StreamListFilterParams;

    if (!params?.limit) return;

    const oldOffset = params?.offset || 0;
    const limit = params?.limit - 1 || DEFAULT_PAGE_LIMIT;

    const requestParams = {
      ...params,
      offset: oldOffset - limit
    };

    yield* fork(fetchStreamsListRequest, requestParams);
  }
}

function* watchRefetchStreamList() {
  while (true) {
    yield* take(takeType(streamActions.reFetchStreamsList));

    const params = (yield* select(streamSelectors.selectStreamsListParams)) as StreamListFilterParams;

    const limit = params?.limit || DEFAULT_PAGE_LIMIT;

    const requestParams = {
      ...params,
      limit
    };

    yield* fork(fetchStreamsListRequest, requestParams);
  }
}

const deleteStreamRequest = asyncEntity(streamActions.deleteStreamAsync, (props: DeleteStreamProps) =>
  api().stream.removeStream(props)
);

function* watchDeleteStream() {
  while (true) {
    const { payload } = yield* take(takeType(streamActions.deleteStream));
    const workspace = yield* select(appMetaSelectors.selectWorkspace);

    yield* fork(deleteStreamRequest, { workspace, ...payload });
  }
}

function* watchDeleteStreamSuccess() {
  while (true) {
    yield* take(takeType(streamActions.deleteStreamAsync.success));

    const streams = yield* select(streamSelectors.selectStreamsList);
    const params = (yield* select(streamSelectors.selectStreamsListParams)) as StreamListFilterParams;

    const page = (params.offset || 0) / (params.limit || 0);
    const canFetchPrevPage = page > 1 && !streams.length;

    const deleteEntity = yield* select(streamSelectors.selectDeleteStreamEntity);
    const StreamOrRecording = deleteEntity.params?.kind === 'RECORD' ? 'Recording' : 'Stream';

    canFetchPrevPage
      ? yield* put(streamActions.fetchStreamsPrevPage())
      : yield* put(streamActions.reFetchStreamsList());

    yield* put(
      appNotificationActions.notify({
        type: 'success',
        description: `${StreamOrRecording} has been deleted`
      })
    );
  }
}

const getStreamRequest = asyncEntity(streamActions.checkStreamAsync, (streamId: string) =>
  api().stream.getStream({ streamId })
);

function* watchCheckStream() {
  while (true) {
    const { payload } = yield* take(streamActions.checkStream);

    yield* fork(getStreamRequest, payload.streamId);
  }
}

const editStreamRequest = asyncEntity(streamActions.editStreamAsync, (props: EditStreamProps) => {
  const { isOpenStudio: __, ...rest } = props;
  const isRecording = props.kind === 'RECORD';

  const request = isRecording ? api().stream.editRecordingMeta : api().stream.updateStream;

  return request(rest);
});

function* watchEditStream() {
  while (true) {
    const {
      payload: { canGuestAddDestination, ...editProps }
    } = yield* take(takeType(streamActions.editStream));

    const workspace = yield* select(appMetaSelectors.selectWorkspace);
    const data = { workspace, ...editProps };

    yield* put(streamActions.checkStream(data));

    const { payload: stream } = yield* take(takeType(streamActions.checkStreamAsync.success));

    const destinations = yield* select(userDestinationsSelectors.selectDestinationsObject);
    const streamDestinationsIds = Object.keys(data.destinationParams || {});
    const streamDestinations = omitBy(
      (_: string, destinationId: string) => !streamDestinationsIds.includes(destinationId)
    )(destinations) as DestinationObject;

    const errorText = getEditStreamError(streamDestinations, stream.state, data);

    if (errorText) {
      yield* put(
        appNotificationActions.notify({
          type: 'error',
          description: errorText
        })
      );
    } else {
      yield* fork(editStreamRequest, data, { ...data, canGuestAddDestination });
    }
  }
}

function* watchEditStreamSuccess() {
  while (true) {
    const { payload } = yield* take(takeType(streamActions.editStreamAsync.success));

    const editStream = yield* select(streamSelectors.selectEditStreamEntity);

    if (editStream.params?.isNotRefetchList) return;

    yield* put(
      appNotificationActions.notify({
        type: 'success',
        description: 'Stream has been updated'
      })
    );

    yield* put(streamActions.reFetchStreamsList());

    if (payload.error) {
      const destinations = yield* select(userDestinationsSelectors.selectDestinationsObject);
      const destinationErrors = getStreamErrorDescription(destinations, payload.error?.message);

      yield* put(
        appNotificationActions.notify({
          type: 'error',
          description: `<b>Failed to update these destinations.</b>${destinationErrors}`
        })
      );
    }
  }
}

function* watchEditStreamFailure() {
  while (true) {
    const { payload } = yield* take(takeType(streamActions.editStreamAsync.failure));

    const errorText = payload.data;
    const description = typeof errorText === 'string' ? errorText : '';

    yield* put(
      appNotificationActions.notify({
        type: 'error',
        description: `Could not update the stream. ${description}`
      })
    );
  }
}

const changeStreamKindRequest = asyncEntity(streamActions.changeStreamKindAsync, (props: ChangeKindParams) =>
  api().stream.changeStreamKind(props)
);

function* watchChangeStreamKind() {
  while (true) {
    const { payload } = yield* take(takeType(streamActions.changeStreamKind));

    yield* fork(changeStreamKindRequest, payload);
  }
}

function* watchChangeStreamKindFailure() {
  while (true) {
    yield* take(takeType(streamActions.changeStreamKindAsync.failure));

    yield* put(
      appNotificationActions.notify({
        type: 'error',
        description: 'Failed to change the stream'
      })
    );
  }
}

const stopStreamRequest = asyncEntity(streamActions.stopStreamAsync, (streamId: string) =>
  api().stream.stopStream({ streamId })
);

function* watchStopStream() {
  while (true) {
    const { payload } = yield* take(takeType(streamActions.stopStream));
    yield* fork(stopStreamRequest, payload);
  }
}

function* watchStopStreamSuccess() {
  while (true) {
    yield* take(takeType(streamActions.stopStreamAsync.success));

    yield* put(
      appNotificationActions.notify({
        type: 'success',
        description: 'Stream has been stopped'
      })
    );
  }
}

function* watchStopStreamFailure() {
  while (true) {
    yield* take(takeType(streamActions.stopStreamAsync.failure));

    yield* put(
      appNotificationActions.notify({
        type: 'error',
        description: 'Failed to stop the stream'
      })
    );
  }
}

function* watchUpdateDestinationMetaEntity() {
  while (true) {
    const { payload } = yield* take(takeType(streamActions.updateDestinationMetaEntity));

    if (payload.error) {
      yield* put(
        appNotificationActions.notify({
          type: 'error',
          description: payload.error.message
        })
      );
    } else if (payload.isUploaded) {
      yield* put(
        appNotificationActions.notify({
          type: 'success',
          description: 'Preview has been uploaded'
        })
      );
    }
  }
}

const duplicateStreamSettingsRequest = asyncEntity(
  streamActions.duplicateStreamAsync,
  (params: DuplicateRequestParams) => api().stream.duplicateStream(params)
);

function* watchDuplicateStreamVideo() {
  while (true) {
    const {
      payload: { roomId, ...createParams }
    } = yield* take(takeType(streamActions.duplicateStream));

    yield* put(streamActions.createStream({ ...createParams, isDuplicate: true }));

    const { payload: createSuccess } = yield* take(takeType(streamActions.createStreamAsync.success));

    const { meta, kind } = createSuccess;

    if (roomId && kind !== 'HOSTED') {
      yield* fork(duplicateStreamSettingsRequest, {
        baseUrl: urls.streamingRoomApi,
        roomIdFrom: roomId,
        roomIdTo: meta.roomId
      });
    }
  }
}

function* watchDuplicateStreamVideoFailure() {
  while (true) {
    yield* take(takeType(streamActions.duplicateStreamAsync.failure));

    yield* put(
      appNotificationActions.notify({
        type: 'error',
        description: 'Failed to duplicate the stream. Please try again later'
      })
    );
  }
}

function* watchDuplicateStreamVideoSuccess() {
  while (true) {
    yield* take(takeType(streamActions.duplicateStreamAsync.success));

    const createStreamParams = yield* select(streamSelectors.selectCreateStreamParams);

    if (createStreamParams) {
      const { kind, schedule } = createStreamParams;

      const tab = !schedule && kind === 'HOSTED' ? 'in-progress' : 'upcoming';

      if (Router.query.tab !== tab) {
        Router.pushRoute('lives', { tab });
      }
    }
  }
}

const fetchStreamRequest = asyncEntity(streamActions.fetchStreamAsync, (params: GetStreamParams) =>
  api().stream.getStream(params)
);

function* watchFetchStream() {
  while (true) {
    const { payload: streamId } = yield* take(takeType(streamActions.fetchStream));
    const workspace = yield* select(appMetaSelectors.selectWorkspace);

    yield* call(fetchStreamRequest, { workspace, streamId });
  }
}

const fetchStreamsByIdsRequest = asyncEntity(streamActions.fetchStreamsByIdsAsync, (params: GetStreamsByIdsParams) =>
  api().stream.getStreamsByIds(params)
);

function* watchFetchStreamsByIds() {
  while (true) {
    const { payload: streamIds } = yield* take(takeType(streamActions.fetchStreamsByIds));
    const workspace = yield* select(appMetaSelectors.selectWorkspace);

    yield* call(fetchStreamsByIdsRequest, { workspace, streamIds });
  }
}

const saveRoomSettingsRequest = asyncEntity(streamActions.saveRoomSettingsAsync, data =>
  api().stream.saveRoomSettings(data)
);

function* watchSaveSettingsSuccess() {
  while (true) {
    yield* take(takeType(streamActions.saveRoomSettingsAsync.success));

    const params = yield* select(streamSelectors.selectSaveSettingsParams);

    if (params?.canNotify) {
      yield* put(
        appNotificationActions.notify({
          type: 'success',
          description: 'Stream settings updated!'
        })
      );
    }
  }
}

function* watchSaveCanAddGuestSettings() {
  while (true) {
    const {
      payload: { roomId, canGuestAddDestination, canNotify }
    } = yield* take(takeType(streamActions.saveCanAddGuestsRoomSettings));

    const oldSettings = yield* select(streamSelectors.selectRoomSettings) || {};

    const data = {
      ...DefaultRoomSettings,
      ...oldSettings,
      roomId,
      guestsCanAddDestinations: canGuestAddDestination
    };

    yield* fork(saveRoomSettingsRequest, data, { ...data, canNotify });
  }
}

const getRoomSettingsRequest = asyncEntity(streamActions.getRoomSettingsAsync, (roomId: string) =>
  api().stream.getRoomSettings(roomId)
);

function* watchGetRoomSettings() {
  while (true) {
    const { payload } = yield* take(takeType(streamActions.getRoomSettings));

    yield* fork(getRoomSettingsRequest, payload);
  }
}

const getGuestDestinationsRequest = asyncEntity(streamActions.fetchGuestsDestinationsAsync, (streamId: string) =>
  api().stream.getGuestDestinations(streamId)
);

function* watchFetchGuestDestinations() {
  while (true) {
    const { payload } = yield* take(takeType(streamActions.fetchGuestsDestinations));

    yield* fork(getGuestDestinationsRequest, payload);
  }
}

function* watchDeleteGuestsDestinationsSuccess() {
  while (true) {
    yield* take(takeType(userDestinationsActions.deleteGuestDestinationsAsync.success));

    const params = yield* select(userDestinationsSelectors.selectDeleteGuestsParams);

    if (params?.editProps) {
      yield* put(streamActions.editStream(params.editProps));
    }
  }
}

const deleteRecordingRequest = asyncEntity(streamActions.deleteRecordingAsync, (data: DeleteStreamProps) =>
  api().stream.removeStream(data)
);

function* watchDeleteRecording() {
  while (true) {
    const { payload: streamId } = yield* take(takeType(streamActions.deleteRecording));
    const workspace = yield* select(appMetaSelectors.selectWorkspace);

    yield* fork(deleteRecordingRequest, { workspace, destinationIds: [], streamId });
  }
}

function* watchDeleteRecordingSuccess() {
  while (true) {
    yield* take(takeType(streamActions.deleteRecordingAsync.success));

    yield* put(
      appNotificationActions.notify({
        type: 'success',
        description: 'Recording deleted successfully.'
      })
    );
  }
}

function* watchDeleteRecordingFailure() {
  while (true) {
    yield* take(takeType(streamActions.deleteRecordingAsync.failure));

    yield* put(
      appNotificationActions.notify({
        type: 'error',
        description: 'Failed to delete recording.'
      })
    );
  }
}

const streamSagaWatchers = () => [
  call(watchMoveStreamToTeam),
  call(watchMoveStreamToUser),
  call(watchCreateStream),
  call(watchFetchStreamsList),
  call(watchFetchStream),
  call(watchEditStream),
  call(watchDeleteStream),
  call(watchStopStream),
  call(watchCheckStream),
  call(watchSaveSettingsSuccess),
  call(watchDeleteRecording),
  call(watchDeleteRecordingSuccess),
  call(watchDeleteRecordingFailure),
  call(watchDeleteGuestsDestinationsSuccess),
  call(watchStreamsEmptyCheck),
  call(watchChangeStreamKind),
  call(watchRefetchStreamList),
  call(watchStopStreamSuccess),
  call(watchStopStreamFailure),
  call(watchEditStreamSuccess),
  call(watchGetRoomSettings),
  call(watchSaveCanAddGuestSettings),
  call(watchFetchGuestDestinations),
  call(watchCreateStreamSuccess),
  call(watchEditStreamFailure),
  call(watchDuplicateStreamVideo),
  call(watchDuplicateStreamVideoSuccess),
  call(watchDuplicateStreamVideoFailure),
  call(watchUpdateDestinationMetaEntity),
  call(watchFetchPrevStreamPage),
  call(watchFetchNextStreamPage),
  call(watchDeleteStreamSuccess),
  call(watchCreateStreamFailure),
  call(watchUpdateStreamResolution),
  call(watchChangeStreamKindFailure),
  call(watchUpdateStreamResolutionFailure),
  call(watchFetchStreamsByIds)
];

export default streamSagaWatchers;
