import EventEmitter from '@anm/helpers/events/EventEmitter';

import { randomString } from '../../randomstring';
import { AddNodeOperation, Operation, RemoveNodeOperation, SetAttributeOperation } from '../OT';

// ------------------ events -------------------------

export class AttrChangeEvent<T> {
  constructor(readonly node: Node, readonly attr: string, readonly newValue: T, readonly oldValue: T) {}

  toJSON(): any {
    return [0, this.node.id, this.attr, this.newValue, this.oldValue];
  }
}

export enum ChildrenEventType {
  added,
  removed
}

export class ChildrenChangeEvent {
  constructor(readonly parent: Node, readonly child: Node, readonly index: number, readonly type: ChildrenEventType) {}

  toJSON(): any {
    return [this.type === ChildrenEventType.added ? 1 : 2, this.parent.id, this.child.id, this.index];
  }
}

// -------------------- tree -------------------------

// export declare type AttrPrimitive = string | number | boolean | bigint | symbol | null | Date
// export declare type AttrValue<T> = T extends AttrPrimitive
//     ? T
// : T extends Array<infer V>
// ? Readonly<Array<V>>
// : Readonly<T>;

export class Node {
  parent?: Node;

  protected _modCount: number = 0;

  protected _attrs: Map<string, any> = new Map<string, any>();

  protected _children: Node[] = [];

  constructor(readonly id: string, children?: Node[]) {
    if (children) this._children = children;
  }

  attrs(): [string, any][] {
    return Array.from(this._attrs.entries());
  }

  hasChildren() {
    return this._children.length > 0;
  }

  childrenCount() {
    return this._children.length;
  }

  append(v: Node) {
    this.add(this._children.length, v);
  }

  add(index: number, v: Node) {
    if (index > this._children.length || index < 0) {
      throw new Error(`invalid index ${index} adding node ${v.toJSON()} to ${this.toJSON()}`);
    }
    if (v.parent) {
      throw new Error(`attempt to add non-free node ${v.toJSON()} to ${this.toJSON()}`);
    }

    v.parent = this;
    this._children.splice(index, 0, v);

    this.fireAttrsChanged(new ChildrenChangeEvent(this, v, index, ChildrenEventType.added));
  }

  deleteByIndex(index: number) {
    if (index < 0) {
      throw new Error(`cannot delete element from ${this.toJSON()}, not found`);
    }

    const v = this._children[index];

    this._children.splice(index, 1);
    v.parent = undefined;

    this.fireAttrsChanged(new ChildrenChangeEvent(this, v, index, ChildrenEventType.removed));
  }

  delete(v: Node) {
    if (v.parent !== this) {
      throw new Error(`attempt to remove foreign node ${v.toJSON()} from ${this.toJSON()}`);
    }

    return this.deleteByIndex(this._children.indexOf(v));
  }

  childById(id: string): [Node, number] | undefined {
    for (let i = 0; i < this._children.length; i++) {
      if (this._children[i].id === id) return [this._children[i], i];
    }
  }

  indexOf(node: Node) {
    return this._children.indexOf(node);
  }

  list(): Node[] {
    return Array.from(this._children);
  }

  get<T>(attr: string, defaultValue?: T): T | undefined {
    const v = this._attrs.get(attr);
    if (v !== undefined && v !== null) return v as T;
    return defaultValue;
  }

  // set<T>(attr: string, value: AttrValue<T>): Node {
  set<T>(attr: string, value: T): Node {
    const oldValue = this._attrs.get(attr);

    if (value !== undefined && value !== null) {
      if (!Node.equals(oldValue, value)) {
        this._attrs.set(attr, value);
        this.fireAttrsChanged(new AttrChangeEvent(this, attr, value, oldValue));
      }
    } else {
      if (!Node.equals(oldValue, value)) {
        this._attrs.delete(attr);

        this.fireAttrsChanged(new AttrChangeEvent(this, attr, value, oldValue));
      }
    }

    return this;
  }

  remove() {
    if (this.parent) {
      this.parent.delete(this);
    }
  }

  eq(node: Node): boolean {
    if (this.id !== node.id) return false;
    if (this._attrs.size !== node._attrs.size) return false;
    if (this._children.length !== node._children.length) return false;

    for (const key of this._attrs.keys()) {
      const thisValue = this._attrs.get(key);
      const nodeValue = node._attrs.get(key);

      if (!Node.equals(thisValue, nodeValue)) return false;
    }

    for (let k = 0; k < this._children.length; k++) {
      if (!this._children[k].eq(node._children[k])) return false;
    }

    return true;
  }

  fromJSON(children: any[]) {
    const nodes: Node[] = [];

    children.forEach(ch => {
      const node = Node.fromJSON(ch);
      node.parent = this;
      nodes.push(node);
    });

    this._children = nodes;
  }

  get modCount() {
    return this._modCount;
  }

  fireAttrsChanged<T>(event: AttrChangeEvent<T> | ChildrenChangeEvent) {
    this._modCount++;

    if (this.parent) {
      this.parent.fireAttrsChanged(event);
    }
  }

  stringify(): string {
    return JSON.stringify(this.toJSON());
  }

  toJSON(): any {
    const obj: any = {};
    obj.id = this.id;

    for (const key of this._attrs.keys()) {
      const val = this._attrs.get(key);
      if (val) {
        obj[key] = val;
      }
    }

    if (this._children.length > 0) obj.c = this._children;

    return obj;
  }

  static equals(a: any, b: any): boolean {
    if (Array.isArray(a) && Array.isArray(b)) {
      if (a.length !== b.length) {
        return false;
      }

      for (let i = 0; i < a.length; i++) {
        if (!Node.equals(a[i], b[i])) {
          return false;
        }
      }

      return true;
    } else if (a !== null && b !== null && typeof a === 'object' && typeof b === 'object') {
      const keys1 = Object.keys(a);
      const keys2 = Object.keys(b);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (const key of keys1) {
        if (!Node.equals(a[key], b[key])) {
          return false;
        }
      }

      return true;
    }

    return a === b;
  }

  static parse<T extends Node>(jsonString: string): T {
    return this.fromJSON(JSON.parse(jsonString));
  }

  static fromJSON<T extends Node>(json: any): T {
    const attrs = new Map<string, any>();

    let id;
    let children;

    Object.keys(json).forEach(k => {
      if (k === 'id') {
        id = json[k];
      } else if (k === 'c') {
        children = json[k];
      } else {
        attrs.set(k, json[k]);
      }
    });

    if (!id) throw new Error(`node does not have id: ${json}`);

    const node = id === 'root' ? new RootNode() : new Node(id);
    node._attrs = attrs;
    if (children) node.fromJSON(children);

    if (node instanceof RootNode) {
      node.init();
    }

    return node as T;
  }

  mapTree(map: Map<string, Node>) {
    map.set(this.id, this);
    for (const child of this._children) {
      child.mapTree(map);
    }
  }

  static newId = () => randomString();
}

export class RootNode extends Node {
  private nodesById = new Map<string, Node>();

  readonly onAttrsEvent = new EventEmitter<AttrChangeEvent<any> | ChildrenChangeEvent>();
  readonly onOpsEvent = new EventEmitter<Operation<RootNode>>();

  constructor() {
    super('root');
    this.nodesById.set('root', this);
  }

  init() {
    this.mapTree(this.nodesById);
  }

  private addTree(parent: Node, child: Node, index: number) {
    this.onOpsEvent.emit(new AddNodeOperation(parent.id, child.id, index));

    for (const attr of child.attrs()) {
      const name = attr[0];
      const value = attr[1];
      this.onOpsEvent.emit(new SetAttributeOperation(child.id, name, undefined, value));
    }

    if (child.hasChildren()) {
      const nodes = child.list();
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        this.addTree(child, node, i);
      }
    }

    this.nodesById.set(child.id, child);
  }

  private removeTree(parent: Node, child: Node, index: number) {
    this.nodesById.delete(child.id);

    if (child.hasChildren()) {
      const nodes = child.list();
      for (let i = nodes.length; --i >= 0; ) {
        const node = nodes[i];
        this.removeTree(child, node, i);
      }
    }

    for (const attr of child.attrs()) {
      const name = attr[0];
      const value = attr[1];
      this.onOpsEvent.emit(new SetAttributeOperation(child.id, name, value, undefined));
    }

    this.onOpsEvent.emit(new RemoveNodeOperation(parent.id, child.id, index));
  }

  getById(nodeId: string) {
    return this.nodesById.get(nodeId);
  }

  public fireAttrsChanged<T>(event: AttrChangeEvent<T> | ChildrenChangeEvent) {
    if (event instanceof ChildrenChangeEvent) {
      const child = event.child;

      if (event.type === ChildrenEventType.added) {
        this.addTree(event.parent, child, event.index);
      } else if (event.type === ChildrenEventType.removed) {
        this.removeTree(event.parent, child, event.index);
      }
    } else if (event instanceof AttrChangeEvent) {
      this.onOpsEvent.emit(new SetAttributeOperation(event.node.id, event.attr, event.oldValue, event.newValue));
    }

    this._modCount++;
    this.onAttrsEvent.emit(event);
  }
}
