import { DAY_MS, HOUR_MS, MINUTE_MS } from '@anm/constants/date';
import getCaps, {
  getMaxDescriptionMessage,
  getMaxScheduleIntervalMessage,
  getMinScheduleIntervalMessage
} from '@anm/data/streaming/destinationsCaps';
import capitalize from '@anm/helpers/string/capitalize';
import { getUpgradeLinkProps } from '@anm/helpers/upgradeLink';
import { Destination, DestinationKind } from 'types/stream';

const getMaxLimitMessage = (isOwner?: boolean) =>
  isOwner ? (
    <span>
      To lift max destinations limit or add destinations of the same type, please{' '}
      <a {...getUpgradeLinkProps()}> upgrade</a>.
    </span>
  ) : (
    <span>This stream has reached maximum number of destinations. Please contact the host.</span>
  );

export const getWarnMessage = (isNoDestinationsWarning: boolean, isOwner?: boolean) =>
  isNoDestinationsWarning ? <span>Please select at least one destination.</span> : getMaxLimitMessage(isOwner);

type BoolProps = {
  isEdit?: boolean;
  isHosted?: boolean;
  isScheduled?: boolean;
  isDuplicate?: boolean;
  isUploading?: boolean;
  isEditDestinations?: boolean;
};

export const getDescription = ({ isScheduled, isHosted, isEditDestinations }: BoolProps) =>
  isEditDestinations
    ? 'We will post/update the event on selected destinations and delete it from the ones you’ve deselected.'
    : !isScheduled && isHosted
    ? 'The video will be streamed to each destination you selected.'
    : isScheduled
    ? `Live streaming event will be ${isEditDestinations ? 'updated' : 'created'} on each destination you selected.`
    : '';

export const getTime = (ms: number) => {
  let time = '';

  if (ms >= DAY_MS) {
    const interval = ms / DAY_MS;
    time = interval === 7 ? '1 week' : ms / DAY_MS + ' days';
  } else if (ms >= HOUR_MS) time = ms / HOUR_MS + ' hour';
  else if (ms >= MINUTE_MS) time = ms / MINUTE_MS + ' minute';

  return time;
};

const goBackAndEditText = 'Please go back and edit settings. ';
const learnMoreLink =
  '<a href="https://wave.video/help/articles/3344065" target="_blank">Learn more</a>';

export const getErrorsText = (destinationErrors: string[]) => {
  const errors = destinationErrors.reduce((acc, err) => {
    const key = err.split('-')[0];
    const network = key.split('.')[0] as Destination;
    const kind = key.split('.')[1] as DestinationKind;

    if (network && err.includes('minScheduleInterval')) {
      const time = getTime(getCaps(network, kind).minScheduleInterval || 0);
      acc += getMinScheduleIntervalMessage(capitalize(network, true), kind, time);
    }
    if (network && err.includes('maxScheduleInterval')) {
      const time = getTime(getCaps(network, kind).maxScheduleInterval || Infinity);
      acc += getMaxScheduleIntervalMessage(capitalize(network, true), kind, time);
    }
    if (network && err.includes('maxDescriptionLength')) {
      acc += getMaxDescriptionMessage(capitalize(network, true), kind);
    }

    return acc;
  }, '');

  return errors + goBackAndEditText + learnMoreLink;
};

export const getSubmitButtonText = ({
  isEdit,
  isHosted,
  isUploading,
  isDuplicate,
  isScheduled,
  isEditDestinations
}: BoolProps) =>
  isHosted && !isScheduled
    ? 'Stream now'
    : isHosted && isScheduled
    ? 'Schedule Stream'
    : isUploading
    ? 'Uploading...'
    : (isEdit && !isDuplicate) || isEditDestinations
    ? 'Save'
    : 'Create Live Stream';

export const getTitleText = ({ isEdit, isDuplicate, isEditDestinations }: BoolProps) =>
  isEdit && !isDuplicate
    ? 'Edit stream destinations'
    : isEditDestinations
    ? 'Add stream destinations'
    : 'Stream destinations';
