import { LAST_USED_BRAND, LAST_USED_STREAM_RESOLUTION } from '@anm/constants/keys';
import { createSelector } from 'reselect';

import { ApplicationState } from '../../types';

export const selectUserPrefs = ({ userPrefs }: ApplicationState) => userPrefs.data;

export const selectPreferredBrandId = createSelector(selectUserPrefs, userPrefs => userPrefs?.[LAST_USED_BRAND]);

export const selectPreferredStreamResolution = createSelector(
  selectUserPrefs,
  userPrefs => userPrefs?.[LAST_USED_STREAM_RESOLUTION] || 'HD720'
);
