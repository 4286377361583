import * as userDestinationsActions from './actions';
import userDestinationsReducer from './reducer';
import * as userDestinationsSelectors from './selectors';

export * from './types';
export { default as userDestinationsSaga } from './saga';

export { userDestinationsActions, userDestinationsSelectors };

export default userDestinationsReducer;
