import { ApiError } from '@anm/api';
import { UserPrefs } from '@anm/api/modules/userprefs';
import { createAction, createAsyncAction } from '@anm/helpers/saga/typesafe-actions';

export const fetchUserPrefs = createAction('wave.video/userprefs/FETCH_PREFS')();
export const updateUserPrefs = createAction('wave.video/userprefs/UPDATE_PREFS')<Partial<UserPrefs>>();
export const updatePreferredBrand = createAction('wave.video/userprefs/UPDATE_PREFFERED_BRAND')<number>();

export const fetchUserPrefsAsync = createAsyncAction(
  'wave.video/userprefs/FETCH_PREFS_REQUEST',
  'wave.video/userprefs/FETCH_PREFS_SUCCESS',
  'wave.video/userprefs/FETCH_PREFS_FAILURE'
)<void, UserPrefs, ApiError>();

export const updateUserPrefsAsync = createAsyncAction(
  'wave.video/userprefs/UPDATE_PREFS_REQUEST',
  'wave.video/userprefs/UPDATE_PREFS_SUCCESS',
  'wave.video/userprefs/UPDATE_PREFS_FAILURE'
)<UserPrefs, ApiError, ApiError>();
