import api, { ApiError } from '@anm/api';
import { HostingElement, MainVideos, SortOptions } from '@anm/api/modules/hosting/types';
import {
  DEFAULT_EMBED_TYPE,
  DEFAULT_POPOVER_TYPE,
  DEFAULT_VIDEO_SIZE_TYPE,
  EMBED_HORIZONTAL_WIDTH,
  EMBED_VERTICAL_WIDTH
} from '@anm/constants/embed';
import { mapConfigs, Manifest, MediaObject, VideoData } from '@anm/hosting-player';

import vtConfig from '../../../config';

import { EmbedData, UpdateConfigs } from './types';

const compareNames = (currentElement: HostingElement, nextElement: HostingElement) => {
  const currentElementName = currentElement.meta.name.toUpperCase();
  const nextElementName = nextElement.meta.name.toUpperCase();
  return currentElementName < nextElementName ? -1 : currentElementName > nextElementName ? 1 : 0;
};

const compareDates = (currentElement: HostingElement, nextElement: HostingElement) => {
  const currentElementUpdatedDate = new Date(currentElement.dateUpdated).getTime();
  const nextElementUpdatedDate = new Date(nextElement.dateUpdated).getTime();

  return nextElementUpdatedDate - currentElementUpdatedDate;
};

export const sortList = (sortBy: SortOptions, list: HostingElement[]): HostingElement[] => {
  switch (sortBy) {
    case 'name':
      return list.sort(compareNames);
    case 'date':
      return list.sort(compareDates);
    default:
      return list;
  }
};

export const getVideoMaxResolution = (array: MainVideos[] = []): number => {
  const maxVideoWidth = array.reduce((acc, video) => {
    return Math.max(acc, video.width);
  }, 0);
  return array.findIndex(({ width }) => width === maxVideoWidth);
};

export const isPosterSizeBig = (file: File): boolean => {
  const posterSizeLimit = 1024 * 1024 * 10;
  return file.size > posterSizeLimit;
};

export const getUpdatedList = (list: HostingElement[] | null, payload: HostingElement) => {
  const updatedVideo = payload;
  const id = updatedVideo.uploadId;
  const oldList = list || [];
  const newVideos = [...oldList];
  const updatedVideoIndex = newVideos.findIndex(({ uploadId }) => uploadId === id);
  newVideos[updatedVideoIndex] = updatedVideo;

  return newVideos;
};

export const getPreview = (check: (prev: MediaObject, next: MediaObject) => boolean) => (
  manifest: Manifest | null,
  whiteTypeList = ['video']
) => {
  if (!manifest?.main) return null;

  return manifest.main.reduce((acc, item) => {
    const { width, type, path, ...other } = item;
    if ((acc && !check(acc, item)) || !whiteTypeList.includes(type)) return acc;

    return {
      ...acc,
      ...other,
      width,
      path
    };
  }, null as null | MediaObject);
};

export const getUpdatedConfigs = ({ configs, sources, playerColor }: UpdateConfigs) => {
  const newConfigsWithColor = {
    ...configs,
    ...(playerColor && { playerColor })
  };

  const updatedPlayerConfigs = sources && mapConfigs(newConfigsWithColor, sources);

  return { newConfigsWithColor, updatedPlayerConfigs };
};

export const getEmbedDataDefaultState = (id: string, videoData: VideoData): EmbedData => {
  const ratio = videoData.sizes.width / videoData.sizes.height;
  const width = ratio >= 1 ? EMBED_HORIZONTAL_WIDTH : EMBED_VERTICAL_WIDTH;
  const height = Math.round(width / ratio);

  return {
    id,
    type: DEFAULT_EMBED_TYPE,
    sizes: { width, height },
    sizeType: DEFAULT_VIDEO_SIZE_TYPE,
    popover: {
      text: '',
      popoverType: DEFAULT_POPOVER_TYPE,
      videoName: videoData.meta.name,
      thumbSrc: `${vtConfig().urls.embed}${id}/preview.jpg`,
      button: {
        enabled: true,
        color: videoData.configs.playerColor
      }
    }
  };
};

export const getUploadsCount = async () => {
  return await api().uploads.getUploadsCount();
};

export const geStorageStats = async () => {
  return await api().user.getUserStorageStats();
};

export const isNotFoundError = ({ code, message }: ApiError) => code === 400 && message.includes('Data was not found');

const sortDownIsOriginalVideo = (a: MediaObject, b: MediaObject) => (a.width === b.width && b.isOriginal ? -1 : 0);
const sortDownBigVideo = (_: MediaObject, b: MediaObject) => (Math.min(b.width, b.height) > 1080 ? -1 : 0);

export const getBestVideoMediaObject = (manifest: Manifest | null) => {
  const videos = manifest?.main
    .filter(m => m.type === 'video')
    .sort((a, b) => b.width - a.width)
    .sort(sortDownIsOriginalVideo)
    .sort(sortDownBigVideo);

  return videos?.[0];
};
