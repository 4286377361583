import { BrandFileMeta } from '@anm/api/modules/brands/types';

export type LogoItem = {
  id: string;
  name: string;
  iconSrc: string;
  url?: string;
  dimension?: {
    width: number;
    height: number;
  };
};

export enum Themes {
  modest = 'modest',
  bubble = 'bubble',
  active = 'active',
  classic = 'classic',
  brick = 'brick',
  digital = 'digital',
  vivid = 'vivid',
  elegant = 'elegant',
  sleek = 'sleek',
  sharp = 'sharp',
  rounded = 'rounded',
  oval = 'oval',
  diamond = 'diamond',
  frame = 'frame',
  oval_frame = 'oval_frame',
  flow = 'flow',
  shadow = 'shadow',
  strong = 'strong',
  glass = 'glass',
  dark_glass = 'dark_glass',
  pixel = 'pixel'
}

export const DEFAULT_THEME = Themes.digital;

export type OverlayItem = {
  id: string;
  src: string;
};

export type MediaItem = {
  src: string; // media url
  id: string; // random id assigned when media is added, used to coordinate correct reset from other peers
  state: 'playing' | 'paused';
  slideId: number;
  startTime: number; // timestamp the media was added to broadcast, used to calculate offset on peers joined after the media was set
  stopTime?: number; // timestamp the media was paused in a broadcast
  meta?: BrandFileMeta;
  loop?: boolean;
  muted?: boolean;
  volume?: number;
  duration?: number;
  currentTime?: number;
};

export type AudioCategory = BrandFileCategories.audio | BrandFileCategories.soundfx;

export type Audio = Omit<Required<MediaItem>, 'meta' | 'slideId' | 'startTime' | 'stopTime'> & {
  category: AudioCategory;
};

export type VideoEndAction = 'endStream' | 'fadeOut' | 'none';

export type VideoItem = MediaItem & {
  mutedIds: string[]; // ids of all muted feeds to unmute after video is over
  pausedIds: string[]; // ids of all paused feeds to play after video is over
  endAction?: VideoEndAction;
};

export enum FrameTypes {
  noframe = 'noframe',
  circle = 'circle',
  rounded = 'rounded',
  square = 'square'
}

export enum BrandFileTypes {
  upload = 'upload',
  stock = 'stock',
  project = 'project'
}

export enum BrandFileCategories {
  overlay = 'overlay',
  background = 'background',
  audio = 'audio',
  video = 'video',
  soundfx = 'soundfx'
}

export type CategoryWithLogo = BrandFileCategories | 'logo';
