import { UploadedVideo } from '@anm/api/modules/hosting/types';
import { Folder } from '@anm/api/modules/videoFolder';
import getSizeFromSrc from '@anm/helpers/getSizeFromSrc';
import isDraft from '@anm/helpers/project/isDraft';
import isArray from 'lodash/fp/isArray';
import { AnimatronProject } from 'project';

import { FolderTreeState, VideoFolderState } from './types';
import isIOS from '@anm/helpers/is/isIOS';
import { DEFAULT_VIDEO_NAME } from '@anm/constants/my-videos';

type AddNewFolderProps = {
  folderTree: Folder;
  parentId: number;
  newFolder: Folder;
};

type RemoveProjectFromListProps = {
  state: VideoFolderState;
  currentFolderId: number;
};

export const addNewFolder = ({ folderTree, parentId, newFolder }: AddNewFolderProps) => {
  folderTree.id === parentId && !folderTree.folders.some(({ id }) => id === newFolder.id)
    ? folderTree.folders.unshift({ ...newFolder })
    : folderTree.folders.forEach(folder => addNewFolder({ parentId, newFolder, folderTree: folder }));
  return folderTree;
};

export const removeFolder = (folder: Folder, removeId: number) => {
  folder.folders = [
    ...folder.folders.filter(folderItem => {
      const isRemovedItem = folderItem.id === removeId;
      if (!isRemovedItem) {
        removeFolder(folderItem, removeId);
      }
      return !isRemovedItem;
    })
  ];

  return folder;
};

export const findFolder = (parentFolder: Folder, folderId: number) => {
  let result;

  if (!folderId || parentFolder.id === folderId) return parentFolder;

  parentFolder.folders.some(folder => {
    result = findFolder(folder, folderId);
    return !!result;
  });

  return result;
};

export const moveFolder = (folder: Folder, removeId: number, toParentId: number) => {
  const movingFolder = findFolder(folder, removeId);

  if (!movingFolder) {
    return folder;
  }

  const folderAfterRemove = removeFolder(folder, removeId);

  return findAndUpdateFolder(
    folderAfterRemove,
    toParentId
  )(v => {
    v.folders.push(movingFolder);
    return v;
  });
};

export const findAndUpdateFolder = (folder: Folder, folderId: number) => (update: (folder: Folder) => Folder): Folder =>
  folder.id === folderId
    ? update(folder)
    : {
        ...folder,
        folders: [
          ...folder.folders.map(v =>
            v.id === folderId ? update(v) : v.folders.length !== 0 ? findAndUpdateFolder(v, folderId)(update) : v
          )
        ]
      };

export const removeProject = ({ projects, ...otherData }: Folder, removeId: string) => ({
  ...otherData,
  projectCount: otherData.projectsCount && otherData.projectsCount - 1,
  projects: [...projects.filter(v => v.id !== removeId)]
});

export const removeProjectByHostId = ({ projects, ...otherData }: Folder, hostId: string) => ({
  ...otherData,
  projectCount: otherData.projectsCount && otherData.projectsCount - 1,
  projects: [...projects.filter(p => p.extra.hostingId !== hostId)]
});

export const findAndUpdateProject = ({ projects, ...otherData }: Folder, projectId: string | string[]) => (
  update: (folder: AnimatronProject) => AnimatronProject
) => {
  const projectsIds = isArray(projectId) ? projectId : [projectId];

  return {
    ...otherData,
    projects: [...projects.map(v => (projectsIds.includes(v.id) ? update(v) : v))]
  };
};

export const addNewProject = (folder: Folder, project: AnimatronProject, folderId: number) => {
  const { id, projects } = folder;
  return folderId !== id
    ? findAndUpdateFolder(folder, folderId)(f => ({ ...f, projects: [project, ...f.projects] }))
    : {
        ...folder,
        projects: [setStatuses(project), ...projects]
      };
};

export const compareDates = (currentItem: Folder | AnimatronProject, nextItem: Folder | AnimatronProject) =>
  nextItem.modified - currentItem.modified;

const setStatuses = (project: AnimatronProject) => ({
  ...project,
  isDraft: isDraft(project),
  isEmbedded: !!project.embeddedAt,
  ...(!project.extra.hostingId && { canStreamVideo: false })
});

export const setStatusesForProjects = (projects: AnimatronProject[]) => projects.map(setStatuses);

export const sortProjects = (projects: AnimatronProject[]) => projects.sort(compareDates);

export const getVideoNameWithDefault = (name: string) => (isIOS() ? DEFAULT_VIDEO_NAME : name);

export const mapUploadedVideoToProject = (video: Partial<UploadedVideo>) =>
  ({
    id: video.id,
    name: video.name,
    width: video.width,
    height: video.height,
    isDraft: false,
    // isLanding: true, // TODO set to true when api ready
    isProtected: false,
    version: 0,
    modified: Date.now(),
    duration: video.duration,
    isEmbedded: false,
    hasTemplate: false,
    preview_metadata: [video.preview || ''],
    extra: {
      hostingId: video.id
    }
  } as AnimatronProject);

export const getProjectPreview = (
  check: (prevSize: number | null, size: number | null) => boolean,
  projectId: string,
  project?: AnimatronProject
) => {
  if (!project) {
    console.warn(`Projects with id: ${projectId} not found`);
    return;
  }

  const defaultPreview = project.preview_metadata[0];

  const smallestPreview =
    !!defaultPreview &&
    (project.preview_metadata as string[]).reduce((acc: string, preview: string) => {
      const size = getSizeFromSrc(preview);
      const prevSize = getSizeFromSrc(acc);

      return check(prevSize, size) ? preview : acc;
    }, '');

  return smallestPreview || defaultPreview;
};

export const getFolderTreeWithout = (folderTree: Folder) => (folderId?: number) => {
  const find = (folder: Folder) => {
    const copy = { ...folder };
    copy.folders = copy.folders.filter(item => item.id !== folderId).map(item => find(item));

    return copy;
  };

  return find(folderTree);
};

export const removeProjectFromFolderTree = ({
  state,
  currentFolderId
}: RemoveProjectFromListProps): FolderTreeState => ({
  ...state.folderTree,
  data: findAndUpdateFolder(
    state.folderTree.data!,
    currentFolderId
  )(({ projectsCount = 0, ...other }) => ({
    ...other,
    projectsCount: projectsCount - 1
  }))
});
