import { getType } from '@anm/helpers/saga/typesafe-actions';
import { Reducer } from 'redux';

import { updateUserPrefsAsync } from '../actions';
import { UserPrefsActions, UserPrefsState } from '../types';

import { initialUserPrefsState } from '.';

const updateUserPrefsReducer: Reducer<UserPrefsState, UserPrefsActions> = (state = initialUserPrefsState, action) => {
  switch (action.type) {
    case getType(updateUserPrefsAsync.request): {
      return {
        ...state,
        updateUserPrefs: {
          ...state.updateUserPrefs,
          isError: false,
          isPending: true
        }
      };
    }
    case getType(updateUserPrefsAsync.success): {
      return {
        ...state,
        updateUserPrefs: {
          ...state.updateUserPrefs,
          isPending: false
        }
      };
    }
    case getType(updateUserPrefsAsync.failure): {
      return {
        ...state,
        updateUserPrefs: {
          ...state.updateUserPrefs,
          isError: true,
          isPending: false,
          error: action.payload
        }
      };
    }
    default:
      return state;
  }
};

export default updateUserPrefsReducer;
