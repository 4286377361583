import { Logger } from '@anm/helpers/Debugger';
import isJSON from '@anm/helpers/is/isJSON';
import isObject from 'lodash/fp/isObject';
import { DestinationObject, LiveStreamError } from 'types/stream';

const logger = new Logger('Stream getStreamErrorDescription');

const createErrorText = (obj: Record<string, string>, destinationsNameMap: DestinationObject | null) => {
  if (!destinationsNameMap) return '';

  return Object.keys(obj).reduce((acc, key) => {
    acc += `<br/><b>${destinationsNameMap[key]?.title}</b>: ${obj[key]}`;

    return acc;
  }, '');
};

const getStreamErrorDescription = (
  destinationsMap: DestinationObject | null,
  error?: LiveStreamError | null | Record<number, string>
) => {
  const errorMsg = isObject(error) ? (error as LiveStreamError)?.message : error;

  let t = '';

  try {
    if (typeof error === 'object' && error && !isJSON((error as LiveStreamError)?.message)) {
      t = createErrorText((error as unknown) as Record<string, string>, destinationsMap);
    } else if (typeof errorMsg === 'string' && isJSON(errorMsg)) {
      const obj = JSON.parse(errorMsg) as Record<string, string>;
      t = createErrorText(obj, destinationsMap);
    } else if (typeof error === 'string') {
      t = error;
    }
  } catch (e) {
    logger.error(e);
  }

  return t;
};

export default getStreamErrorDescription;
