import { getType } from '@anm/helpers/saga/typesafe-actions';
import { Reducer } from 'redux';

import { fetchUserPrefsAsync } from '../actions';
import { UserPrefsActions, UserPrefsState } from '../types';

import { initialUserPrefsState } from '.';

const fetchUserPrefsReducer: Reducer<UserPrefsState, UserPrefsActions> = (state = initialUserPrefsState, action) => {
  switch (action.type) {
    case getType(fetchUserPrefsAsync.request): {
      return {
        ...state,
        fetchUserPrefs: {
          ...state.fetchUserPrefs,
          isError: false,
          isPending: true
        }
      };
    }
    case getType(fetchUserPrefsAsync.success): {
      return {
        ...state,
        data: action.payload,
        fetchUserPrefs: {
          ...state.fetchUserPrefs,
          isPending: false
        }
      };
    }
    case getType(fetchUserPrefsAsync.failure): {
      return {
        ...state,
        fetchUserPrefs: {
          ...state.fetchUserPrefs,
          isError: true,
          isPending: false,
          error: action.payload
        }
      };
    }
    default:
      return state;
  }
};

export default fetchUserPrefsReducer;
