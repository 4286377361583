import api from '@anm/api';
import {
  RemoveGuestDestinationsParams,
  RemoveSocialDestinationRequest,
  RtmpNetworkRequest,
  ToggleDestinationsMap
} from '@anm/api/modules/stream';
import asyncEntity from '@anm/helpers/redux/asyncEntity';

import { userDestinationsActions as actions } from '..';

export const fetchUserDestinationsRequest = asyncEntity(actions.fetchDestinationsListAsync, (query: string) =>
  api().stream.getDestinations(query)
);

export const checkUserDestinationsRequest = asyncEntity(actions.checkDestinationsAsync, () =>
  api().stream.socialCheck()
);

export const toggleUserDestinationsRequest = asyncEntity(
  actions.toggleSocialDestinationsAsync,
  (map: ToggleDestinationsMap) => api().stream.toggleSocialDestinations(map)
);

export const deleteUserDestinationRequest = asyncEntity(
  actions.deleteSocialDestinationAsync,
  (props: RemoveSocialDestinationRequest) => api().stream.removeSocialDestination(props)
);

export const addRTMPDestinationRequest = asyncEntity(actions.addRTMPDestinationAsync, (data: RtmpNetworkRequest) =>
  api().stream.addRTMPDestination(data)
);

export const deleteGuestDestinations = asyncEntity(
  actions.deleteGuestDestinationsAsync,
  (props: RemoveGuestDestinationsParams) => api().stream.removeGuestDestinations(props)
);
