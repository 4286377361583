import { getType } from '@anm/helpers/saga/typesafe-actions';
import { Reducer } from 'redux';

import { analyticsActions } from '..';
import { AnalyticsActions } from '../types';

const initialDataRangeState = [0, 0];

const dateRangeReducer: Reducer<typeof initialDataRangeState, AnalyticsActions> = (
  state = initialDataRangeState,
  action
) => {
  switch (action.type) {
    case getType(analyticsActions.updateDateRange): {
      return action.payload;
    }
    default:
      return state;
  }
};

export default dateRangeReducer;
