import asyncEntityHandlers from '@anm/helpers/redux/asyncEntityHandlers';
import reduceFactory from '@anm/helpers/redux/reduceFactory';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';

import { streamActions, StreamActions } from '..';
import { RoomSettingsState } from '../types';

const initialState: RoomSettingsState = {
  data: null,
  isError: false,
  isPending: false,
  saveSettings: {
    data: null,
    params: null,
    isError: false,
    isPending: false
  }
};

const roomSettingsReducers: Reducer<RoomSettingsState, StreamActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(streamActions.clearRoomSettings):
      return initialState;

    default:
      return reduceFactory(initialState, {
        ...asyncEntityHandlers<RoomSettingsState>(streamActions.getRoomSettingsAsync, undefined, true),
        ...asyncEntityHandlers<RoomSettingsState>(streamActions.saveRoomSettingsAsync, 'saveSettings', true)
      })(state, action);
  }
};

export default roomSettingsReducers;
