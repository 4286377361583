import { Meta } from '@anm/api/modules/brands';
import { Node, RootNode } from '@anm/shared/model/Node';
import { Audio, DEFAULT_THEME, Themes } from '@anm/shared/types/branding';
import { Attr, Element } from '@anm/shared/types/element';
import { fixDate, fixDates } from '@anm/shared/types/helper';
import { Destination, LiveStreamStatus } from 'types/stream';
import Url from 'url-parse';

import { FeedType } from './feeds';

export type BrandRef = {
  brandId: number;
  userId: string;
};

export type RoomSettings = {
  anonymousGuestsAllowed: boolean;
  guestsSeeComments: boolean;
  guestsCanAddDestinations: boolean;
  isGuestEnterSound: boolean;
  canGuestShare: boolean;
  isShiftVideo: boolean;
  keepStreamingWithoutGuests: boolean;
  showNamesInLiveChat: boolean;
  recordSeparateAudioTracks: boolean;
  brand?: BrandRef;
  region?: string;
};

export type StreamChange = { status: LiveStreamStatus };
export type StreamInprogressChange = { startedAt: number };
export type StreamEndChange = { endedAt: number };
export type StreamStatusChange = StreamChange & Partial<StreamEndChange | StreamInprogressChange>;

export const DefaultRoomSettings: RoomSettings = {
  anonymousGuestsAllowed: true,
  guestsSeeComments: true,
  guestsCanAddDestinations: true,
  isGuestEnterSound: true,
  canGuestShare: false,
  keepStreamingWithoutGuests: false,
  showNamesInLiveChat: true,
  recordSeparateAudioTracks: false,
  isShiftVideo: true
};

export const DEFAULT_REGION = 'us-east-1';

export type RoomMeta = {
  id: string;

  ownerId: string;

  streamId: string;

  region: string;

  // direct url of node where room is activated (if active)
  url?: string;

  // room is active when at least one participant is in it
  active: boolean;

  settings: RoomSettings;

  secretToken: string;

  // timestamp room was activated and assigned backend
  activatedAt?: Date;
  deactivatedAt?: Date;
  createdAt?: Date;
};

export const fixRoom = (r: RoomMeta): RoomMeta => {
  return fixDates(r, ['activatedAt', 'deactivatedAt', 'createdAt']);
};

export type RoomStats = {
  peers: number;
  webinarPeers: number;
  streaming: boolean;
  videoStreams: number;
  audioStreams: number;
  updatedAt: Date;
};

export enum UserRole {
  owner = 'owner',

  // can fully manage room, same as for owner
  // change destinations, start/stop live
  // can add to and remove from stream other users
  // can mute/unmute, show camera/hide camera, delete other users, ban/kick users
  // can control the layout and show messages, comments etc
  can_manage_room = 'can_manage_room',

  // can see other user's feeds
  // can add/remove from stream her own and other feeds
  // can change layout
  // can show her banners
  // can show her media from brand
  can_manage_stream = 'can_manage_stream',

  // cannot see other user's feeds
  // can add/remove her own feeds to stream
  // can change layout
  // can show her banners
  // can show her media from brand
  can_manage_own_stream = 'can_manage_own_stream',

  // can see and answer comments even when guests do not see comments
  can_manage_comments = 'can_manage_comments'
}

const userRolesMap: { [key in UserRole]: number } = {
  [UserRole.can_manage_comments]: 10,
  [UserRole.can_manage_own_stream]: 20,
  [UserRole.can_manage_stream]: 30,
  [UserRole.can_manage_room]: 40,
  [UserRole.owner]: 100
};

const rolesLabelMap: { [key in UserRole]: string } = {
  [UserRole.can_manage_comments]: 'Support',
  [UserRole.can_manage_own_stream]: 'Presenter',
  [UserRole.can_manage_stream]: 'Co-Host',
  [UserRole.can_manage_room]: 'Admin',
  [UserRole.owner]: 'Owner'
};

export type UserParticipationRole = 'studio' | 'chat' | 'comments' | 'broadcast';

export type User = {
  userId: string; // wave user id for registered users and virtual user id for non-wave users
  name: string; // user display name
  email?: string; // email, used for webinar
  roles: UserRole[];
  registered: boolean; // true for registered users
  avatar?: string; // pic avatar url
  title?: string; // user title
  participationRole: UserParticipationRole;
};

export type UserGrant = Pick<User, 'userId' | 'name' | 'registered' | 'roles' | 'avatar'>;

export type YTSuper = {
  tier: number;
  currency: string;
  amountMicros: number;
  amountDisplayString: string;
};

export type YTSuperChatMeta = YTSuper & {
  userComment: string;
};

export type YTSuperStickerMeta = YTSuper & {
  altText: string;
  altTextLanguage: string;
  stickerId: string;
};

export type BroadcastNotification = {
  id: string;
  text: string;
  slideId: number;
  timeout?: number;
};

export const FAV_TAG = 'fav';
export const SHOWN_TAG = 'shown';
export const DELETED_TAG = 'deleted';
export const AllTags = [FAV_TAG, SHOWN_TAG, DELETED_TAG];

export type CommentMetaTags = typeof FAV_TAG | typeof SHOWN_TAG | typeof DELETED_TAG;
export type CommentMeta = {
  tags: CommentMetaTags[];
};

export type RoomCommentMeta = CommentMeta & {
  emotes?: string;
  youtubeSuperChat?: YTSuperChatMeta;
  youtubeSuperSticker?: YTSuperStickerMeta;
};

export type RoomComment = {
  id: string; // comment id
  roomId: string; // room id
  videoId: string; // hosted video id
  network: Destination;
  authorId: string; // author id in the network
  authorName: string; // author display name
  avatar: string; // author avatar url
  message: string; // comment message
  meta?: RoomCommentMeta;
  isOwn?: boolean;
  commentedAt: Date; // "2021-06-05T08:45:32Z"
};

export const fixRoomComment = (m: RoomComment) => fixDate<RoomComment>(m, 'commentedAt');

export type ProducerAppData = {
  peerId: string;
  feedId: string;
  feedType: FeedType;
};

export type ConsumerScalability = {
  type: 'simple' | 'simulcast' | 'svc';
  spatialLayers: number;
  temporalLayers: number;
};

export type RoomSheetMeta = {
  roomId: string;
  data?: string;
  createdAt: Date;
};

export enum ShiftPolicy {
  none,
  auto,
  always
}

export enum Format {
  vertical = 'vertical',
  horizontal = 'horizontal'
}

export enum MediaItemType {
  'shared_video',
  'background_audio',
  'brand_video'
}

export type MediaItemState = {
  id: string;
  type: MediaItemType;
  position: number;
  slideId?: number;
};

export type ThemeFont = {
  name: string;
  face: string;
  size: number;
  style: string;
  weight: number;
  lineHeight: number;
  fontLink: string;
};

export enum RaffleStage {
  draw = 'DRAW',
  result = 'RESULT',
  hide = 'HIDE'
}

export type Alignment = 'left' | 'center' | 'right';

export type RoomSheetEntity = 'nameTheme' | 'captionTheme' | 'commentTheme';

const initColors = [] as string[];
const initFont = { name: '', face: '', size: 0, weight: 0, style: 'normal', lineHeight: 20, fontLink: '' };

export class RoomSheet extends Element<RootNode> {
  static audios = new Attr<Audio[]>('audios', []);
  static mediaItems = new Attr<MediaItemState[]>('mediaItems', []);
  static commentPolicy = new Attr<ShiftPolicy>('commentPolicy', ShiftPolicy.none);
  static format = new Attr<Format>('format', Format.horizontal);

  static commentTheme = new Attr<Themes>('commentTheme', DEFAULT_THEME);
  static commentThemeOriginal = new Attr<Themes>('commentThemeOriginal', DEFAULT_THEME);
  static commentColors = new Attr<string[]>('commentColors', initColors);
  static commentFont1 = new Attr<ThemeFont>('commentFont1', initFont);
  static commentFont2 = new Attr<ThemeFont>('commentFont2', initFont);

  static captionTheme = new Attr<Themes>('captionTheme', DEFAULT_THEME);
  static captionThemeOriginal = new Attr<Themes>('captionThemeOriginal', DEFAULT_THEME);
  static captionColors = new Attr<string[]>('captionColors', initColors);
  static captionFont1 = new Attr<ThemeFont>('captionFont1', initFont); // text
  static captionFont2 = new Attr<ThemeFont>('captionFont2', initFont); // title

  static nameTheme = new Attr<Themes>('nameTheme', DEFAULT_THEME);
  static nameThemeOriginal = new Attr<Themes>('nameThemeOriginal', DEFAULT_THEME);
  static nameColors = new Attr<string[]>('nameColors', initColors);
  static nameFont1 = new Attr<ThemeFont>('nameFont1', initFont);
  static nameFont2 = new Attr<ThemeFont>('nameFont2', initFont);

  static localAudioRecording = new Attr<boolean>('localAudioRecording', false);
  static localVideoRecording = new Attr<boolean>('localVideoRecording', false);

  static raffleStage = new Attr<RaffleStage>('raffleStage', RaffleStage.hide);
  static raffleWinner = new Attr<RoomComment | null>('raffleWinner', null);

  static textToolText = new Attr<string>('textToolText', '');
  static textToolTheme = new Attr<string>('textToolTheme', '');
  static textToolBrandId = new Attr<number>('textToolBrandId', 0);
  static textToolFontSize = new Attr<number>('textToolFontSize', 40);
  static textToolParagraphAlignment = new Attr<Alignment>('textToolParagraphAlignment', 'left');
  static textToolParagraphPlacement = new Attr<Alignment>('textToolParagraphPlacement', 'left');
  static textToolScroll = new Attr<number>('textToolScroll', 0);
  static textToolBrandMeta = new Attr<Meta>('textToolBrandMeta', {} as Meta);
  static textToolBrandColor = new Attr<string>('textToolBrandColor', '');

  constructor(node: RootNode) {
    super(node);

    this.read();
  }

  private read() {
    this.node.list();
  }

  get modCount() {
    return this.node.modCount;
  }

  set audios(audios: Audio[]) {
    RoomSheet.audios.set(this.node, Array.from(audios));
  }

  get audios() {
    return Array.from(RoomSheet.audios.get(this.node));
  }

  set mediaItems(items: MediaItemState[]) {
    RoomSheet.mediaItems.set(this.node, Array.from(items));
  }

  get mediaItems() {
    return Array.from(RoomSheet.mediaItems.get(this.node));
  }

  set commentPolicy(policy: ShiftPolicy) {
    RoomSheet.commentPolicy.set(this.node, policy);
  }

  get commentPolicy() {
    return RoomSheet.commentPolicy.get(this.node);
  }

  get commentColors() {
    return RoomSheet.commentColors.get(this.node);
  }

  set commentColors(colors: string[]) {
    RoomSheet.commentColors.set(this.node, colors);
  }

  get captionColors() {
    return RoomSheet.captionColors.get(this.node);
  }

  set captionColors(colors: string[]) {
    RoomSheet.captionColors.set(this.node, colors);
  }

  get nameColors() {
    return RoomSheet.nameColors.get(this.node);
  }

  set nameColors(colors: string[]) {
    RoomSheet.nameColors.set(this.node, colors);
  }

  get commentFont1() {
    return RoomSheet.commentFont1.get(this.node);
  }

  set commentFont1(font: ThemeFont) {
    RoomSheet.commentFont1.set(this.node, font);
  }

  get commentFont2() {
    return RoomSheet.commentFont2.get(this.node);
  }

  set commentFont2(font: ThemeFont) {
    RoomSheet.commentFont2.set(this.node, font);
  }

  get captionFont1() {
    return RoomSheet.captionFont1.get(this.node);
  }

  set captionFont1(font: ThemeFont) {
    RoomSheet.captionFont1.set(this.node, font);
  }

  get captionFont2() {
    return RoomSheet.captionFont2.get(this.node);
  }

  set captionFont2(font: ThemeFont) {
    RoomSheet.captionFont2.set(this.node, font);
  }

  get nameFont1() {
    return RoomSheet.nameFont1.get(this.node);
  }

  set nameFont1(font: ThemeFont) {
    RoomSheet.nameFont1.set(this.node, font);
  }

  get nameFont2() {
    return RoomSheet.nameFont2.get(this.node);
  }

  set nameFont2(font: ThemeFont) {
    RoomSheet.nameFont2.set(this.node, font);
  }

  get commentTheme() {
    return RoomSheet.commentTheme.get(this.node);
  }

  set commentTheme(theme: Themes) {
    RoomSheet.commentTheme.set(this.node, theme);
  }

  get captionTheme() {
    return RoomSheet.captionTheme.get(this.node);
  }

  set captionTheme(theme: Themes) {
    RoomSheet.captionTheme.set(this.node, theme);
  }

  get nameTheme() {
    return RoomSheet.nameTheme.get(this.node);
  }

  set nameTheme(theme: Themes) {
    RoomSheet.nameTheme.set(this.node, theme);
  }

  set format(format: Format) {
    RoomSheet.format.set(this.node, format);
  }

  get format() {
    return RoomSheet.format.get(this.node);
  }

  set commentThemeOriginal(theme: Themes) {
    RoomSheet.commentThemeOriginal.set(this.node, theme);
  }

  get commentThemeOriginal() {
    return RoomSheet.commentThemeOriginal.get(this.node);
  }

  set captionThemeOriginal(theme: Themes) {
    RoomSheet.captionThemeOriginal.set(this.node, theme);
  }

  get captionThemeOriginal() {
    return RoomSheet.captionThemeOriginal.get(this.node);
  }

  set nameThemeOriginal(theme: Themes) {
    RoomSheet.nameThemeOriginal.set(this.node, theme);
  }

  get nameThemeOriginal() {
    return RoomSheet.nameThemeOriginal.get(this.node);
  }

  get localRecording() {
    return this.localVideoRecording || this.localAudioRecording;
  }

  get localVideoRecording() {
    return RoomSheet.localVideoRecording.get(this.node);
  }

  set localVideoRecording(value: boolean) {
    RoomSheet.localVideoRecording.set(this.node, value);
  }

  get localAudioRecording() {
    return RoomSheet.localAudioRecording.get(this.node);
  }

  set localAudioRecording(value: boolean) {
    RoomSheet.localAudioRecording.set(this.node, value);
  }

  get raffleStage() {
    return RoomSheet.raffleStage.get(this.node);
  }

  set raffleStage(value: RaffleStage) {
    RoomSheet.raffleStage.set(this.node, value);
  }

  get raffleWinner() {
    return RoomSheet.raffleWinner.get(this.node);
  }

  set raffleWinner(value: RoomComment | null) {
    RoomSheet.raffleWinner.set(this.node, value);
  }

  get textToolText() {
    return RoomSheet.textToolText.get(this.node);
  }

  set textToolText(props: string) {
    RoomSheet.textToolText.set(this.node, props);
  }

  get textToolTheme() {
    return RoomSheet.textToolTheme.get(this.node);
  }

  set textToolTheme(props: string) {
    RoomSheet.textToolTheme.set(this.node, props);
  }

  get textToolBrandId() {
    return RoomSheet.textToolBrandId.get(this.node);
  }

  set textToolBrandId(id: number) {
    RoomSheet.textToolBrandId.set(this.node, id);
  }

  get textToolParagraphAlignment() {
    return RoomSheet.textToolParagraphAlignment.get(this.node);
  }

  set textToolParagraphAlignment(align: Alignment) {
    RoomSheet.textToolParagraphAlignment.set(this.node, align);
  }

  get textToolParagraphPlacement() {
    return RoomSheet.textToolParagraphPlacement.get(this.node);
  }

  set textToolParagraphPlacement(position: Alignment) {
    RoomSheet.textToolParagraphPlacement.set(this.node, position);
  }

  get textToolScroll() {
    return RoomSheet.textToolScroll.get(this.node);
  }

  set textToolScroll(scroll: number) {
    RoomSheet.textToolScroll.set(this.node, scroll);
  }

  get textToolFontSize() {
    return RoomSheet.textToolFontSize.get(this.node);
  }

  set textToolFontSize(size: number) {
    RoomSheet.textToolFontSize.set(this.node, size);
  }

  get textToolBrandColor() {
    return RoomSheet.textToolBrandColor.get(this.node);
  }

  set textToolBrandColor(color: string) {
    RoomSheet.textToolBrandColor.set(this.node, color);
  }
  get textToolBrandMeta() {
    return RoomSheet.textToolBrandMeta.get(this.node);
  }

  set textToolBrandMeta(meta: Meta) {
    RoomSheet.textToolBrandMeta.set(this.node, meta);
  }

  static fromJSON(json: any) {
    return new RoomSheet(Node.fromJSON(json));
  }

  static newSheet() {
    const slide = new RoomSheet(new RootNode());
    return slide;
  }
}

// streaming.test.stacks.animatron-test.com/?backend=0
// localhost:4443/
export const roomRestUrl = (parms: { url: string; path: string; protocol: string }) => {
  const { url, path, protocol } = parms;

  const parsed = new Url(`http://${url}`);

  return `${protocol}://${parsed.hostname}${parsed.port ? `:${parsed.port}` : ''}${path}${parsed.query || ''}`;
};

export const getUserRoleLevel = (role?: UserRole) => (role && userRolesMap[role]) || 0;
export const getUserRoleLabel = (role?: UserRole) => (role && rolesLabelMap[role]) || 'Guest';
