import api from '@anm/api';
import { UserPrefs } from '@anm/api/modules/userprefs';
import asyncEntity from '@anm/helpers/redux/asyncEntity';
import { call, fork, take } from '@anm/helpers/saga/effects';
import { takeType } from '@anm/helpers/saga/typesafe-actions';

import { userPrefsActions } from '.';

const fecthUserPrefsAsync = asyncEntity(userPrefsActions.fetchUserPrefsAsync, () => api().userprefs.getUserPrefs());

const updatePrefferedBrandAsync = asyncEntity(userPrefsActions.updateUserPrefsAsync, (brandId: number) =>
  api().userprefs.updatePrefferedBrand(brandId)
);
const updateUserPrefsAsync = asyncEntity(userPrefsActions.updateUserPrefsAsync, (data: Partial<UserPrefs>) =>
  api().userprefs.updateUserPrefs(data)
);

function* watchFetchUserPrefs() {
  while (true) {
    yield* take(takeType(userPrefsActions.fetchUserPrefs));

    yield* fork(fecthUserPrefsAsync);
  }
}

function* watchUpdateUserPrefs() {
  while (true) {
    const { payload }: ReturnType<typeof userPrefsActions.updateUserPrefs> = yield* take(
      takeType(userPrefsActions.updateUserPrefs)
    );

    yield* fork(updateUserPrefsAsync, payload);
  }
}

function* watchUpdatePrefferedBrand() {
  while (true) {
    const { payload }: ReturnType<typeof userPrefsActions.updatePreferredBrand> = yield* take(
      takeType(userPrefsActions.updatePreferredBrand)
    );

    yield* fork(updatePrefferedBrandAsync, payload);
  }
}

const userPrefsWatchers = () => [
  call(watchFetchUserPrefs),
  call(watchUpdateUserPrefs),
  call(watchUpdatePrefferedBrand)
];

export default userPrefsWatchers;
