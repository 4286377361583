export const VIDEO_ID_ATTR = 'data-id';
export const VIDEO_WIDTH_ATTR = 'data-width';
export const VIDEO_HEIGHT_ATTR = 'data-height';
export const POPOVER_ACTION = 'data-target';
export const POPOVER_TARGET = 'target';
export const DIALOG_SELECTOR = 'embed_popover_dialog';
export const POPOVER_SELECTOR = 'wave_popover_embed';
export const POPOVER_SCRIPT_NAME = 'popover-embed';

export const DEFAULT_EMBED_TYPE = 'inline';
export const DEFAULT_POPOVER_TYPE = 'thumbnail';
export const DEFAULT_VIDEO_SIZE_TYPE = 'responsive';

export const LINK_TEXT = 'Watch video';
export const HIGHLIGHT_TEXT = 'New Collection';
export const LINK_FIELD_TITLE = 'Text';
export const HIGHLIGHT_FIELD_TITLE = 'Highlight title';

export const WATCHED_VIDEO_CLASSNAME = 'watched';
export const HIGHLIGHT_WRAPPER_ClASSNAME = 'img_wrapper';

export const MOBILE_MODAL_GAP = 19;
export const DESKTOP_MODAL_GAP = 70;
export const THUMB_MIN_WIDHT = 440;
export const EMBED_HORIZONTAL_WIDTH = 560;
export const EMBED_VERTICAL_WIDTH = 360;
