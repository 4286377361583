import { RemoveSocialDestinationRequest } from '@anm/api/modules/stream';
import { CHECK_DESTINATIONS_KEY } from '@anm/constants/keys';
import browserStore from '@anm/helpers/browserStore';
import { takeType } from '@anm/helpers/saga/typesafe-actions';
import { buffers } from 'redux-saga';
import { actionChannel, call, fork, put, select, take } from 'typed-redux-saga';
import { ActionWithPayload } from 'types/store';

import { userDestinationsActions as actions, userDestinationsSelectors } from '..';
import { appNotificationActions } from '../../appNotifications';

import * as entities from './entities';
import * as sagaHandlers from './handlers';

export function* watchAddRTMPDestination() {
  while (true) {
    const { payload } = yield* take(takeType(actions.addRTMPDestination));

    yield* fork(entities.addRTMPDestinationRequest, payload);
  }
}

export function* watchFetchDestinationsList() {
  while (true) {
    const {
      payload: { query }
    }: ReturnType<typeof actions.fetchDestinationsList> = yield* take(takeType(actions.fetchDestinationsList));

    if (!query) {
      yield* put(actions.checkDestinations());
    }

    yield* fork(entities.fetchUserDestinationsRequest, query);
  }
}

export function* watchDeleteSocialDestination() {
  const requestChain = yield* actionChannel(
    takeType(actions.deleteSocialDestination),
    buffers.expanding<ActionWithPayload<string, RemoveSocialDestinationRequest>>()
  );

  while (true) {
    const { payload } = yield take(requestChain);

    yield fork(entities.deleteUserDestinationRequest, payload);
  }
}

export function* watchToggleSocialDestinations() {
  while (true) {
    const { payload }: ReturnType<typeof actions.toggleSocialDestinations> = yield* take(
      takeType(actions.toggleSocialDestinations)
    );

    yield* fork(entities.toggleUserDestinationsRequest, payload);
  }
}

export function* watchAddRTMPDestinationSuccess() {
  while (true) {
    yield* take(takeType(actions.addRTMPDestinationAsync.success));

    yield* fork(sagaHandlers.trackAddRTMPDestination);

    yield put(actions.fetchDestinationsList({ query: '' }));
  }
}

export function* watchAddRTMPDestinationFailure() {
  while (true) {
    yield* take(takeType(actions.addRTMPDestinationAsync.failure));

    yield* put(
      appNotificationActions.notify({
        type: 'error',
        description: 'Failed to add RTMP destination'
      })
    );
  }
}

export function* watchDeleteSocialDestinationSuccess() {
  while (true) {
    yield* take(takeType(actions.deleteSocialDestinationAsync.success));
    const canNotify = yield* select(userDestinationsSelectors.selectDeleteDestinationNotify);

    if (canNotify) {
      yield* put(
        appNotificationActions.notify({
          type: 'success',
          description: 'Destination has been deleted'
        })
      );
    }
  }
}

export function* watchDeleteSocialDestinationFailure() {
  while (true) {
    const { payload } = yield* take(takeType(actions.deleteSocialDestinationAsync.failure));
    const canNotify = yield* select(userDestinationsSelectors.selectDeleteDestinationNotify);

    const error = payload.data;

    if (canNotify) {
      yield* put(
        appNotificationActions.notify({
          type: 'error',
          description: `Failed to delete the destination. ${error ? error : ''}`
        })
      );
    }

    yield* put(actions.clearDeleteUserDestinations());
  }
}

export function* watchToggleSocialDestinationsSuccess() {
  while (true) {
    const { payload } = yield* take(takeType(actions.toggleSocialDestinationsAsync.success));

    yield* call(sagaHandlers.trackAddDestinationSuccess, payload);
  }
}

export function* watchCheckDestinations() {
  while (true) {
    yield* take(takeType(actions.checkDestinations));

    browserStore.set(CHECK_DESTINATIONS_KEY, Date.now());

    yield* fork(entities.checkUserDestinationsRequest);
  }
}

export function* watchDeleteGuestDestinations() {
  while (true) {
    const { payload } = yield* take(takeType(actions.deleteGuestDestinations));

    yield* fork(entities.deleteGuestDestinations, payload);
  }
}
