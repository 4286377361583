import { Node, RootNode } from '../Node';

export interface Operation<M> {
  execute(model: M): void;

  transform(op: Operation<M>, server: boolean): Operation<M>;

  transformThrough(op: Operation<M>, server: boolean): Operation<M>;

  invert(): Operation<M>;

  readonly nop: boolean;

  toJSON(): any;
}

enum OpType {
  nop,
  add_node,
  del_node,
  set_attr
}

export abstract class BaseOperation<M> implements Operation<M> {
  abstract execute(model: M): void;

  transform(op: Operation<M>, server: boolean): Operation<M> {
    return this.doTransform(op, server);
  }

  transformThrough(op: Operation<M>, server: boolean): Operation<M> {
    return this.doTransformThrough(op, server);
  }

  protected doTransform(op: Operation<M>, server: boolean): Operation<M> {
    return op.transformThrough(this, server);
  }

  protected doTransformThrough(_op: Operation<M>, _server: boolean): Operation<M> {
    return this;
  }

  get nop() {
    return false;
  }

  abstract invert(): Operation<M>;

  abstract toJSON(): any;
}

export class NopOperation<M> extends BaseOperation<M> {
  constructor() {
    super();
  }

  execute(_model: M): void {}

  invert(): Operation<M> {
    return this;
  }

  toJSON(): any {
    return OpType.nop;
  }

  get nop() {
    return true;
  }
}

export abstract class AttributeOperation<M> extends BaseOperation<M> {
  constructor(readonly id: string, readonly attrName: string, readonly oldValue: any, readonly newValue: any) {
    super();
  }

  protected matches(op: AttributeOperation<M>): boolean {
    return op.id === this.id && op.attrName === this.attrName;
  }

  protected doTransform(op: Operation<M>, server: boolean): Operation<M> {
    if (op instanceof AttributeOperation) {
      if (this.matches(op)) {
        if (server) {
          return new NopOperation<M>();
        } else {
          return this.createOtherSetOperation(op);
        }
      }
      return op;
    }

    return super.doTransform(op, server);
  }

  protected doTransformThrough(op: Operation<M>, server: boolean): Operation<M> {
    if (op instanceof RemoveChildOperation) {
      if (op.childId === this.id) {
        return new NopOperation<M>();
      }
    }
    return super.doTransformThrough(op, server);
  }

  invert(): Operation<M> {
    return this.createAttributeOperation(this.newValue, this.oldValue);
  }

  protected createOtherSetOperation(op2: AttributeOperation<M>): Operation<M> {
    return this.createAttributeOperation(this.newValue, op2.newValue);
  }

  protected abstract createAttributeOperation(oldValue: any, newValue: any): Operation<M>;
}

export enum IndexOpType {
  add,
  remove
}

export interface IndexedOperation<M, T> extends Operation<M> {
  readonly target: T;
  readonly index: number;
  readonly type: IndexOpType;

  changeIndex(newIndex: number): IndexedOperation<M, T>;
}

export interface IndexedAddOperation<M, T> extends IndexedOperation<M, T> {
  changeIndex(newIndex: number): IndexedAddOperation<M, T>;
}

export interface IndexedRemoveOperation<M, T> extends IndexedOperation<M, T> {
  changeIndex(newIndex: number): IndexedRemoveOperation<M, T>;
}

export class CompositeOperation<M> extends BaseOperation<M> {
  private myOps: Operation<M>[];

  constructor(ops: Operation<M>[]) {
    super();
    this.myOps = Optimizer.flatten(ops);
  }

  invert(): Operation<M> {
    const inverseOps: Operation<M>[] = [];
    for (let i = this.myOps.length; --i >= 0; ) {
      inverseOps.push(this.myOps[i].invert());
    }
    return new CompositeOperation<M>(inverseOps);
  }

  execute(model: M) {
    for (const op of this.myOps) {
      op.execute(model);
    }
  }

  protected doTransform(op: Operation<M>, server: boolean): Operation<M> {
    let result = op;
    for (const o of this.myOps) {
      result = o.transform(result, server);
    }
    return result;
  }

  protected doTransformThrough(op: Operation<M>, server: boolean): Operation<M> {
    let currentOp = op;
    const newOps: Operation<M>[] = [];
    for (const o of this.myOps) {
      newOps.push(currentOp.transform(o, server));
      currentOp = o.transform(currentOp, !server);
    }
    return new CompositeOperation<M>(newOps);
  }

  get ops() {
    return this.myOps;
  }

  get nop() {
    for (const o of this.myOps) {
      if (!o.nop) return false;
    }
    return true;
  }

  toJSON(): any {
    throw Error(); // it should never be serialized
  }
}

const index_transformer = <M, T>(op1: IndexedOperation<M, T>, op2: IndexedOperation<M, T>, server: boolean) => {
  if (op1.target !== op2.target) {
    return op2;
  }

  if (op1.type === IndexOpType.add && op2.type === IndexOpType.add) {
    let res: IndexedOperation<M, T> = op2;
    if (op1.index < op2.index) {
      res = op2.changeIndex(op2.index + 1);
    } else if (op1.index === op2.index) {
      if (server) {
        res = op2.changeIndex(op2.index + 1);
      }
    }
    return res;
  }

  if (op1.type === IndexOpType.remove && op2.type === IndexOpType.remove) {
    if (op1.index === op2.index) {
      return new NopOperation<M>();
    }

    if (op1.index > op2.index) {
      return op2;
    } else {
      return op2.changeIndex(op2.index - 1);
    }
  }

  if (op1.type === IndexOpType.remove && op2.type === IndexOpType.add) {
    if (op1.index >= op2.index) {
      return op2;
    } else {
      return op2.changeIndex(op2.index - 1);
    }
  }

  if (op1.type === IndexOpType.add && op2.type === IndexOpType.remove) {
    if (op2.index >= op1.index) {
      return op2.changeIndex(op2.index + 1);
    } else {
      return op2;
    }
  }

  throw new Error(`cannot transform operations: ${op1}, ${op2}`);
};

export abstract class ChildOperation extends BaseOperation<RootNode> implements IndexedOperation<RootNode, string> {
  abstract readonly parentId: string;
  abstract readonly childId: string;

  constructor() {
    super();
  }

  get target() {
    return this.parentId;
  }

  abstract readonly index: number;

  abstract readonly type: IndexOpType;

  abstract changeIndex(newIndex: number): IndexedOperation<RootNode, string>;

  protected doTransform(op: Operation<RootNode>, server: boolean): Operation<RootNode> {
    if (op instanceof ChildOperation) {
      return index_transformer(this, op, server);
    }
    return super.doTransform(op, server);
  }
}

export abstract class InsertChildOperation extends ChildOperation implements IndexedAddOperation<RootNode, string> {
  constructor(readonly parentId: string, readonly childId: string, readonly index: number) {
    super();
  }

  protected doTransform(op: Operation<RootNode>, server: boolean): Operation<RootNode> {
    if (op instanceof InsertChildOperation) {
      if (op.childId === this.childId) {
        if (op.target !== this.target || op.index !== this.index) {
          if (server) {
            return new NopOperation<RootNode>();
          } else {
            return new CompositeOperation<RootNode>([this.invert(), op]);
          }
        }

        return new NopOperation<RootNode>();
      }
    }

    if (op instanceof RemoveChildOperation) {
      if (op.childId === this.parentId) {
        return new CompositeOperation<RootNode>([this.invert(), op]);
      }
    }

    return super.doTransform(op, server);
  }
}

export abstract class RemoveChildOperation extends ChildOperation implements IndexedRemoveOperation<RootNode, string> {
  constructor(readonly parentId: string, readonly childId: string, readonly index: number) {
    super();
  }

  protected doTransform(op: Operation<RootNode>, server: boolean): Operation<RootNode> {
    if (op instanceof InsertChildOperation) {
      if (op.parentId === this.childId) {
        return new NopOperation<RootNode>();
      }
    } else if (op instanceof RemoveChildOperation) {
      if (op.childId === this.childId) {
        return new NopOperation<RootNode>();
      }
    }

    return super.doTransform(op, server);
  }
}

export class AddNodeOperation extends InsertChildOperation {
  readonly type = IndexOpType.add;

  constructor(parentId: string, childId: string, index: number) {
    super(parentId, childId, index);
  }

  changeIndex(newIndex: number): IndexedOperation<RootNode, string> {
    return new AddNodeOperation(this.parentId, this.childId, newIndex);
  }

  invert(): Operation<RootNode> {
    return new RemoveNodeOperation(this.parentId, this.childId, this.index);
  }

  execute(model: RootNode) {
    const parent = model.getById(this.parentId);
    if (!parent) {
      throw new Error(`cannot find parent ${this.parentId}`);
    }

    parent.add(this.index, new Node(this.childId));
  }

  toJSON(): any {
    return [OpType.add_node, this.parentId, this.childId, this.index];
  }

  static from(ar: any[]) {
    return new AddNodeOperation(ar[1], ar[2], ar[3]);
  }
}

export class RemoveNodeOperation extends RemoveChildOperation {
  readonly type = IndexOpType.remove;

  constructor(parentId: string, childId: string, index: number) {
    super(parentId, childId, index);
  }

  changeIndex(newIndex: number): IndexedOperation<RootNode, string> {
    return new RemoveNodeOperation(this.parentId, this.childId, newIndex);
  }

  invert(): Operation<RootNode> {
    return new AddNodeOperation(this.parentId, this.childId, this.index);
  }

  execute(model: RootNode) {
    const parent = model.getById(this.parentId);
    if (!parent) {
      throw new Error(`cannot find parent ${this.parentId}`);
    }

    const childById = parent.childById(this.childId);
    if (!childById) {
      throw new Error(`cannot find node ${this.childId} in ${this.parentId}`);
    }

    if (childById[1] !== this.index) {
      throw new Error(`node index mismatch: ${childById[1]} but should be ${this.index}`);
    }

    parent.deleteByIndex(this.index);
  }

  toJSON(): any {
    return [OpType.del_node, this.parentId, this.childId, this.index];
  }

  static from(ar: any[]) {
    return new RemoveNodeOperation(ar[1], ar[2], ar[3]);
  }
}

export class SetAttributeOperation extends AttributeOperation<RootNode> {
  constructor(id: string, attrName: string, oldValue: any, newValue: any) {
    super(id, attrName, oldValue, newValue);
  }

  protected createAttributeOperation(oldValue: any, newValue: any): Operation<RootNode> {
    return new SetAttributeOperation(this.id, this.attrName, oldValue, newValue);
  }

  execute(model: RootNode): void {
    const node = model.getById(this.id);
    if (node) {
      node.set(this.attrName, this.newValue);
    } else {
      throw new Error(`cannot find node ${this.id}`);
    }
  }

  toJSON(): any {
    return [OpType.set_attr, this.id, this.attrName, this.oldValue, this.newValue];
  }

  static from(ar: any[]) {
    return new SetAttributeOperation(ar[1], ar[2], ar[3], ar[4]);
  }
}

export const createOps = (json: []): Operation<RootNode>[] => {
  const ops: Operation<RootNode>[] = [];

  for (let i = 0; i < json.length; i++) {
    const o = json[i];
    if (o === OpType.nop) {
      ops.push(new NopOperation());
    } else {
      const ar = o as any[];
      const type = ar[0] as number;
      switch (type) {
        case OpType.add_node:
          ops.push(AddNodeOperation.from(ar));
          break;
        case OpType.del_node:
          ops.push(RemoveNodeOperation.from(ar));
          break;
        case OpType.set_attr:
          ops.push(SetAttributeOperation.from(ar));
          break;
        default:
          throw new Error(`invalid op code ${type} in ${json}`);
      }
    }
  }
  return ops;
};

export class Optimizer<M> {
  private ops: Operation<M>[] | null;

  // todo:
  // private oldValues = new Map<AttributeOperation<M>, Object>()
  // private newValues = new Map<AttributeOperation<M>, Object>()

  constructor(ops: Operation<M>[]) {
    this.ops = ops;
  }

  optimize(_forward: boolean): Operation<M>[] {
    return Optimizer.flatten(this.ops);
  }

  flatten(): Operation<M>[] {
    const flatOps = Optimizer.flatten(this.ops);
    this.ops = null;
    return flatOps;
  }

  static flatten<M>(ops: Operation<M>[] | null): Operation<M>[] {
    const result: Operation<M>[] = [];
    if (ops) {
      for (const op of ops) {
        if (op instanceof CompositeOperation) {
          result.push(...Optimizer.flatten(op.ops));
        } else if (op.nop) {
          // skip
        } else {
          result.push(op);
        }
      }
    }
    return result;
  }

  static optimize<M>(ops: Operation<M>[], forward: boolean) {
    return new Optimizer(ops).optimize(forward);
  }
}
