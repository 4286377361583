import { EditStreamProps } from '@anm/api/modules/stream';
import { getTime } from '@anm/components/stream/NewStreamModal/components/StreamDestinations/helpers';
import getCaps, {
  getMaxScheduleIntervalMessage,
  getMinScheduleIntervalMessage
} from '@anm/data/streaming/destinationsCaps';
import capitalize from '@anm/helpers/string/capitalize';
import { DestinationObject, LiveStreamStatus, SocialDestination } from 'types/stream';

import { SortProps } from './types';

export const sortStreams = ({ streams, getValue }: SortProps) =>
  streams?.sort((prev, next) => new Date(getValue(next)).getTime() - new Date(getValue(prev)).getTime());

export const getResolutionHeight = (...resolutions: (string | undefined)[]) =>
  Math.min(...(resolutions.filter(Boolean) as string[]).map(r => +r.replace('HD', '')));

export const getEditStreamError = (
  destinations: DestinationObject | null,
  state: LiveStreamStatus,
  editProps: EditStreamProps
) => {
  const isLiveInprogressState = state === 'INPROGRESS' && editProps.kind !== 'RECORD';
  const timeDiff = editProps.schedule && new Date(editProps.schedule).getTime() - Date.now();

  const destinationsList = Object.keys(destinations || []).reduce((acc, d) => {
    destinations && acc.push(destinations[d]);

    return acc;
  }, [] as SocialDestination[]);

  const timeError = destinationsList.reduce((acc, destination) => {
    if (!timeDiff) return;

    const { network, kind } = destination;
    const caps = getCaps(network, kind);

    if (caps.minScheduleInterval && timeDiff < caps.minScheduleInterval) {
      const time = getTime(getCaps(network, kind).minScheduleInterval || 0);
      acc += getMinScheduleIntervalMessage(capitalize(network, true), kind || '', time);
    }

    if (caps.maxScheduleInterval && timeDiff > caps.maxScheduleInterval) {
      const time = getTime(getCaps(network, kind).maxScheduleInterval || 0);
      acc += getMaxScheduleIntervalMessage(capitalize(network, true), kind || '', time);
    }
    return acc;
  }, '');

  const errorText = isLiveInprogressState ? 'Stream already started' : timeError ? timeError : null;

  return errorText;
};
