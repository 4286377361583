import asyncEntityHandlers from '@anm/helpers/redux/asyncEntityHandlers';
import pickRequestFailure from '@anm/helpers/redux/pickRequestFailure';
import reduceFactory from '@anm/helpers/redux/reduceFactory';
import { Reducer } from 'redux';
import { DestinationObject } from 'types/stream';
import { getType } from 'typesafe-actions';

import { streamActions, StreamActions } from '..';
import { GuestsDestinationsState } from '../types';

const initialState: GuestsDestinationsState = {
  isError: false,
  isPending: false,
  data: null,
  params: null
};

const guestsDestinationsReducer: Reducer<GuestsDestinationsState, StreamActions> = (state = initialState, action) => {
  switch (action.type) {
    case getType(streamActions.fetchGuestsDestinationsAsync.success):
      const users = Object.keys(action.payload);
      const data = users.reduce((acc, userId) => {
        const destinations = action.payload[userId];
        const ids = Object.keys(destinations);

        ids.forEach(id => {
          acc[id] = {
            ...destinations[id],
            user_id: userId
          };
        });

        return acc;
      }, {} as DestinationObject);

      return { ...state, isPending: true, data };
    default:
      return reduceFactory(initialState, {
        ...asyncEntityHandlers<GuestsDestinationsState>(pickRequestFailure(streamActions.fetchGuestsDestinationsAsync))
      })(state, action);
  }
};

export default guestsDestinationsReducer;
