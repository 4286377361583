import { getType } from '@anm/helpers/saga/typesafe-actions';
import { Reducer } from 'redux';

import { projectActions } from '..';
import { ProjectActions } from '../types';

import { initialProjectsState } from './index';

const { fetchProjectsAsync, incrementProjectCount, decrementProjectCount, toggleProjectStreamModal } = projectActions;

const projectListReducer: Reducer<typeof initialProjectsState, ProjectActions> = (
  state = initialProjectsState,
  action
) => {
  switch (action.type) {
    case getType(fetchProjectsAsync.request): {
      return {
        ...state,
        projectItems: {
          ...state.projectItems,
          isError: false,
          isPending: true
        }
      };
    }
    case getType(fetchProjectsAsync.success): {
      const { list: prevList, offset, limit } = state.projectItems;
      const { count, results: newList = [] } = action.payload;

      return {
        ...state,
        projectItems: {
          ...state.projectItems,
          count,
          isProjectsEmpty: count <= 0,
          list: [...prevList, ...newList],
          offset: offset + limit,
          isPending: false,
          wasFetched: true
        }
      };
    }
    case getType(fetchProjectsAsync.failure): {
      const { payload: newError } = action;
      return {
        ...state,
        projectItems: {
          ...state.projectItems,
          isError: true,
          error: newError,
          isPending: false
        }
      };
    }
    case getType(incrementProjectCount): {
      return {
        ...state,
        projectItems: {
          ...state.projectItems,
          count: state.projectItems.count || 0 + 1
        }
      };
    }
    case getType(decrementProjectCount): {
      return {
        ...state,
        projectItems: {
          ...state.projectItems,
          count: state.projectItems.count ? state.projectItems.count - 1 : 0
        }
      };
    }
    case getType(toggleProjectStreamModal): {
      return {
        ...state,
        projectItems: {
          ...state.projectItems,
          isStreamModalOpen: action.payload
        }
      };
    }
    default:
      return state;
  }
};

export default projectListReducer;
