import { combineReducers } from 'redux';

import guestsDestinationsReducer from './guestsDestinations';
import projectStreamsReducers from './projectStreamsReducer';
import roomSettingsReducer from './roomSettingsReducer';
import streamReducer from './streamReducer';
import streamsListReducer from './streamsListReducer';

const streamingReducer = combineReducers({
  streamsList: streamsListReducer,
  stream: streamReducer,
  projectStreams: projectStreamsReducers,
  roomSettings: roomSettingsReducer,
  guestsDestinations: guestsDestinationsReducer
});

export default streamingReducer;
