import * as analytic from '@anm/analytic';
import parseDestinationExtra from '@anm/helpers/stream/parseDestinationExtra';
import { select } from 'typed-redux-saga';
import { DestinationObject } from 'types/stream';

import { userDestinationsSelectors, DestinationError } from '../index';

export function* trackAddDestinationSuccess(payload: DestinationObject) {
  const params = yield* select(userDestinationsSelectors.selectDestinationsListParams);

  if (!params) return;

  const addedDestinationId = Object.keys(params)[0];
  const destination = payload[addedDestinationId];

  if (!destination?.network) return;

  const isNotAllowedLinkedinDestination =
    destination.network === 'LINKEDIN' && !parseDestinationExtra(destination)['allowed'];

  const isError = isNotAllowedLinkedinDestination || typeof (destination as DestinationError) === 'string';

  if (isError) return;

  const destinationAlias = `${destination.network}_${destination.kind}`.toLowerCase();

  analytic.trackMyStreamsAddDestination(destinationAlias);
}

export function* trackAddRTMPDestination() {
  const params = yield* select(userDestinationsSelectors.selectDestinationsListParams);

  const type = (params?.kind || 'RTMP') as string;

  analytic.trackMyStreamsAddDestination(type);
}
