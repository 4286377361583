import { ApiError } from '@anm/api';
import { ProjectListParams, ProjectListResponse } from '@anm/api/modules/projects';
import { createAction, createAsyncAction } from '@anm/helpers/saga/typesafe-actions';
import { AnimatronProject } from 'project';

export const clearCurrentProject = createAction('wave.video/project/CLEAR_PROJECT')();

export const fetchProject = createAction('wave.video/project/FETCH_PROJECT')<string>();

export const fetchProjectAsync = createAsyncAction(
  'wave.video/project/FETCH_PROJECT_REQUEST',
  'wave.video/project/FETCH_PROJECT_SUCCESS',
  'wave.video/project/FETCH_PROJECT_FAILURE'
)<string, AnimatronProject, ApiError>();

export const fetchProjectsCount = createAction('wave.video/project/FETCH_PROJECTS_COUNT')();

export const fetchProjectsAsync = createAsyncAction(
  'wave.video/project/FETCH_PROJECTS_REQUEST',
  'wave.video/project/FETCH_PROJECTS_SUCCESS',
  'wave.video/project/FETCH_PROJECTS_FAILURE'
)<ProjectListParams, ProjectListResponse, ApiError>();

export const changeSearchOpen = createAction('wave.video/project/CHANGE_SEARCH_OPEN_STATUS')<boolean>();
export const searchProjects = createAction('wave.video/project/SEARCH_PROJECTS')<string>();
export const cancelSearchProjects = createAction('wave.video/project/CANCEL_SEARCH_PROJECTS')();
export const fetchSearchProjectsAsync = createAsyncAction(
  'wave.video/project/FETCH_SEARCH_PROJECTS_REQUEST',
  'wave.video/project/FETCH_SEARCH_PROJECTS_SUCCESS',
  'wave.video/project/FETCH_SEARCH_PROJECTS_FAILURE'
)<ProjectListParams, ProjectListResponse, ApiError>();

export const incrementProjectCount = createAction('wave.video/project/INCREMENT_PROJECT_COUNT')();

export const decrementProjectCount = createAction('wave.video/project/DECREMENT_PROJECT_COUNT')();

export const toggleProjectStreamModal = createAction('wave.video/project/TOGGLE_STREAM_MODAL')<boolean>();
