import { ApiError } from '@anm/api';
import {
  RemoveGuestDestinationsParams,
  RemoveSocialDestinationRequest,
  RtmpNetworkRequest,
  ToggleDestinationsMap
} from '@anm/api/modules/stream';
import { createAction, createAsyncAction } from '@anm/helpers/saga/typesafe-actions';
import { DestinationObject } from 'types/stream';

import { FetchDestinationsListFilter } from './types';

export const fetchDestinationsList = createAction('wave.video/stream/FETCH_DESTINATIONS_LIST')<
  FetchDestinationsListFilter
>();
export const checkDestinations = createAction('wave.video/stream/CHECK_DESTINATIONS')();
export const deleteSocialDestination = createAction('wave.video/stream/DELETE_SOCIAL_DESTINATION')<
  RemoveSocialDestinationRequest
>();
export const toggleSocialDestinations = createAction('wave.video/stream/TOGGLE_SOCIAL_DESTINATIONS')<
  ToggleDestinationsMap
>();

export const addRTMPDestination = createAction('wave.video/stream/ADD_RTMP_DESTINATION')<RtmpNetworkRequest>();
export const cleanDestinations = createAction('wave.video/stream/CLEAN_DESTINATIONS')();
export const clearDeleteUserDestinations = createAction('wave.video/stream/CLEAR_DELETE_USER_DESTINATIONS')();

export const fetchDestinationsListAsync = createAsyncAction(
  'wave.video/stream/FETCH_DESTINATIONS_LIST_REQUEST',
  'wave.video/stream/FETCH_DESTINATIONS_LIST_SUCCESS',
  'wave.video/stream/FETCH_DESTINATIONS_LIST_FAILURE'
)<void, DestinationObject, ApiError>();

export const checkDestinationsAsync = createAsyncAction(
  'wave.video/stream/CHECK_DESTINATIONS_REQUEST',
  'wave.video/stream/CHECK_DESTINATIONS_SUCCESS',
  'wave.video/stream/CHECK_DESTINATIONS_FAILURE'
)<void, DestinationObject, ApiError>();

export const deleteSocialDestinationAsync = createAsyncAction(
  'wave.video/stream/DELETE_SOCIAL_DESTINATION_REQUEST',
  'wave.video/stream/DELETE_SOCIAL_DESTINATION_SUCCESS',
  'wave.video/stream/DELETE_SOCIAL_DESTINATION_FAILURE'
)<RemoveSocialDestinationRequest, DestinationObject, ApiError>();

export const toggleSocialDestinationsAsync = createAsyncAction(
  'wave.video/stream/TOGGLE_SOCIAL_DESTINATION_REQUEST',
  'wave.video/stream/TOGGLE_SOCIAL_DESTINATION_SUCCESS',
  'wave.video/stream/TOGGLE_SOCIAL_DESTINATION_FAILURE'
)<ToggleDestinationsMap, DestinationObject, ApiError>();

export const addRTMPDestinationAsync = createAsyncAction(
  'wave.video/stream/ADD_RTMP_DESTINATION_REQUEST',
  'wave.video/stream/ADD_RTMP_DESTINATION_SUCCESS',
  'wave.video/stream/ADD_RTMP_DESTINATION_FAILURE'
)<RtmpNetworkRequest, DestinationObject, ApiError>();

export const deleteGuestDestinations = createAction('wave.video/stream/DELETE_GUEST_DESTINATIONS')<
  RemoveGuestDestinationsParams
>();

export const deleteGuestDestinationsAsync = createAsyncAction(
  'wave.video/stream/DELETE_GUEST_DESTINATIONS_REQUEST',
  'wave.video/stream/DELETE_GUEST_DESTINATIONS_SUCCESS',
  'wave.video/stream/DELETE_GUEST_DESTINATIONS_FAILURE'
)<RemoveGuestDestinationsParams, string, ApiError>();
