import selectProps from '@anm/helpers/store/selectProps';
import { Password } from '@anm/hosting-player';
import isEqual from 'lodash/fp/isEqual';
import omitBy from 'lodash/fp/omitBy';
import { createSelector } from 'reselect';

import { ApplicationState } from '../../../types';
import { getBestVideoMediaObject, getPreview } from '../helpers';

export const selectHostedVideo = ({ hostedVideo }: ApplicationState) => hostedVideo;

export const selectCreatedVideoEntity = createSelector(selectHostedVideo, hostedVideo => hostedVideo.createVideo);

export const selectCreatedVideoPending = createSelector(
  selectHostedVideo,
  hostedVideo => hostedVideo.createVideo.isPending
);

// export const selectCreatedFile = createSelector(selectHostedVideo, hostedVideo => hostedVideo.createVideo.file);

// export const selectCreatedVideoData = ({ hostedVideo }: ApplicationState) => {
//   const { url } = hostedVideo.createVideo.data!.request;
//   const uploadId = hostedVideo.createVideo.data!.data.upload.id;
//   const hostId = hostedVideo.createVideo.data!.hostId;
//   const uploadType = hostedVideo.createVideo.params?.uploadType || 'new';
//   return { url, hostId, uploadId, uploadType };
// };

// export const selectIsUploadCreatedVideo = createSelector(
//   selectHostedVideo,
//   hostedVideo => hostedVideo.createVideo.params?.isUpload
// );

// export const selectCreatedVideoFolderId = createSelector(
//   selectHostedVideo,
//   hostedVideo => hostedVideo.createVideo.params?.folderId
// );

// export const selectCreatedVideo = createSelector(
//   selectHostedVideo,
//   hostedVideo => hostedVideo.createVideo.data?.data.upload
// );

// export const selectCreatedUploadId = createSelector(
//   selectHostedVideo,
//   hostedVideo => hostedVideo.createVideo.data?.data.upload.id
// );

// export const selectCreatedHostId = createSelector(
//   selectHostedVideo,
//   hostedVideo => hostedVideo.createVideo.data?.hostId
// );

export const selectCanRetryCreateVideo = createSelector(
  selectHostedVideo,
  hostedVideo => hostedVideo.createVideo.params?.canRetry
);

// export const selectCreateVideoError = createSelector(selectHostedVideo, hostedVideo => hostedVideo.createVideo.error);

export const selectManifest = createSelector(selectHostedVideo, hostedVideo => hostedVideo.getManifest.manifest);

export const selectGetManifestPending = createSelector(
  selectHostedVideo,
  hostedVideo => hostedVideo.getManifest.isPending
);
export const selectGetManifestError = createSelector(selectHostedVideo, hostedVideo => hostedVideo.getManifest.error);

export const selectHostIdFromGetManifest = createSelector(
  selectHostedVideo,
  hostedVideo => hostedVideo.getManifest.params?.hostId
);

// export const selectCreatedVideoFor = createSelector(
//   selectCreatedUploadId,
//   selectProps<string | null>(),
//   (id, projectId) => (id === projectId ? id : null)
// );

export const selectManifestFor = createSelector(selectManifest, selectProps<string | null>(), (manifest, projectId) =>
  manifest?.projectId === projectId || manifest?.id === projectId ? manifest : null
);

export const selectSmallestPreview = createSelector(selectProps<string | null>(), selectManifestFor, (_, manifest) =>
  getPreview((prev, { width }) => !!width && prev.width > width)(manifest!)
);

export const selectBiggestPreview = createSelector(selectProps<string | null>(), selectManifestFor, (_, manifest) =>
  getPreview((prev, { width }) => prev.width < width)(manifest!)
);

export const selectBestQualityVideo = createSelector(
  selectManifest,
  manifest => getBestVideoMediaObject(manifest)?.path
);

export const selectAudioSrc = createSelector(
  selectManifest,
  manifest => manifest?.main?.find(({ type }) => type === 'audio')?.path
);

export const selectSaveConfigsStatus = createSelector(selectHostedVideo, hostedVideo => hostedVideo.saveVideoConfigs);

export const selectSavePosterStatus = createSelector(selectHostedVideo, hostedVideo => hostedVideo.uploadPoster);

export const selectSavePasswordStatus = createSelector(selectHostedVideo, hostedVideo => hostedVideo.saveVideoPassword);

export const selectUploadVideo = createSelector(selectHostedVideo, hostedVideo => hostedVideo.uploadVideo);

export const selectUploadVideoId = ({ hostedVideo }: ApplicationState) => hostedVideo.uploadVideo.id;

// export const selectUploadVideoPending = createSelector(
//   selectHostedVideo,
//   hostedVideo => hostedVideo.uploadVideo.isPending
// );

export const selectUploadVideoProgress = createSelector(
  selectHostedVideo,
  hostedVideo => hostedVideo.uploadVideo.progress
);

export const selectUploadVideoStatus = createSelector(
  selectHostedVideo,
  hostedVideo => hostedVideo.uploadVideo.isUploaded
);

export const selectUploadVideoFor = createSelector(
  selectUploadVideoId,
  selectUploadVideoProgress,
  selectUploadVideoStatus,
  selectProps<string | null>(),
  (id, progress, isUploaded, projectId) => (id === projectId ? { progress, isUploaded } : null)
);

export const selectVideoData = createSelector(selectHostedVideo, hostedVideo => hostedVideo?.videoData);

export const selectVideoSources = createSelector(selectHostedVideo, hostedVideo => hostedVideo.videoData?.sources);

export const selectVideoSizes = createSelector(selectHostedVideo, hostedVideo => hostedVideo.videoData?.sizes);

export const selectPlayerColor = createSelector(
  selectHostedVideo,
  hostedVideo => hostedVideo.videoData?.configs.playerColor
);

export const selectVideoPassword = createSelector(selectHostedVideo, hostedVideo => hostedVideo.videoData?.password);

export const selectVideoUrl = createSelector(selectHostedVideo, hostedVideo => hostedVideo.videoData?.bestQualityVideo);

export const selectVideoConfigs = createSelector(selectHostedVideo, hostedVideo => hostedVideo.videoData?.configs);

export const selectPoster = createSelector(selectHostedVideo, hostedVideo => hostedVideo.videoData?.poster);

export const selectSavedConfigs = createSelector(
  selectHostedVideo,
  hostedVideo => hostedVideo.videoData?.savedData.configs
);

export const selectSavedColor = createSelector(
  selectHostedVideo,
  hostedVideo => hostedVideo.videoData?.savedData.configs.playerColor
);

export const selectSavedPoster = createSelector(
  selectHostedVideo,
  hostedVideo => hostedVideo.videoData?.savedData.poster
);

export const selectVideoDataUploadId = createSelector(
  selectHostedVideo,
  hostedVideo => hostedVideo.videoData?.uploadId
);

export const selectSavedPassword = createSelector(
  selectHostedVideo,
  hostedVideo => hostedVideo.videoData?.savedData.password
);

export const selectVideoName = createSelector(selectHostedVideo, hostedVideo => hostedVideo.videoData?.meta.name);

export const selectDeleteVideoPending = createSelector(
  selectHostedVideo,
  hostedVideo => hostedVideo.deleteVideo.isPending
);

export const selectHostedVideosList = createSelector(selectHostedVideo, hostedVideo => hostedVideo.list);

export const selectHostedVideoPending = createSelector(selectHostedVideo, hostedVideo => hostedVideo.isPending);

export const selectSaveEmbed = createSelector(selectHostedVideo, hostedVideo => hostedVideo.saveEmbedStatus);

export const selectEmbedStatus = createSelector(selectHostedVideo, hostedVideo => hostedVideo.embedVideo.status);

export const selectHostedVideoId = createSelector(selectHostedVideo, hostedVideo => hostedVideo.id);

export const selectWasPosterChanged = createSelector(
  selectPoster,
  selectSavedPoster,
  (currentPoster, savedPoster) => currentPoster?.posterUrl !== savedPoster?.posterUrl
);

export const selectWasConfigsChanged = createSelector(
  selectVideoConfigs,
  selectSavedConfigs,
  selectPlayerColor,
  selectSavedColor,
  (currentConfigs, savedCondigs, currentColor, savedColor) =>
    !isEqual(currentConfigs, savedCondigs) || currentColor !== savedColor
);

export const selectWasPasswordChanged = createSelector(
  selectVideoPassword,
  selectSavedPassword,
  (currentPassword, savedPassword) => {
    const isBoolean = (k: any) => typeof k === 'boolean';

    const prevParams: Password = omitBy(isBoolean)(currentPassword);
    const savedParams: Password = omitBy(isBoolean)(savedPassword);

    return !isEqual(prevParams, savedParams);
  }
);

export const selectSavePosterPending = createSelector(
  selectHostedVideo,
  hostedVideo => hostedVideo.uploadPoster.isPending
);

export const selectSaveConfigsPending = createSelector(
  selectHostedVideo,
  hostedVideo => hostedVideo.saveVideoConfigs.isPending
);

export const selectSavePasswordPending = createSelector(
  selectHostedVideo,
  hostedVideo => hostedVideo.saveVideoPassword.isPending
);

export const selectCheckedUpload = createSelector(selectHostedVideo, hostedVideo => hostedVideo.upload);

export const selectVideoAudio = createSelector(selectHostedVideo, hostedVideo => hostedVideo.videoData?.audio);

export const selectEmbedData = createSelector(selectHostedVideo, hostedVideo => hostedVideo.embedVideo.data);

export const selectPopoverEmbed = createSelector(
  selectHostedVideo,
  hostedVideo => hostedVideo.embedVideo.data?.popover
);

export const selectThumbnailEmbedButtonStatus = createSelector(
  selectHostedVideo,
  hostedVideo => hostedVideo.embedVideo.data?.popover.button.enabled
);

export const selectPosterStatus = createSelector(
  selectHostedVideo,
  hostedVideo => hostedVideo.uploadPoster.posterStatus
);

export const selectUploadError = createSelector(selectHostedVideo, hostedVideo => hostedVideo.upload?.error);

export const selectNotificationsStatus = createSelector(selectHostedVideo, hostedVideo => hostedVideo.canNotify);

export const selectHostId = createSelector(selectHostedVideo, hostedVideo => hostedVideo?.id);
