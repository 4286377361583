import { toObject } from '@anm/helpers/parseParams';
import { SocialDestination } from 'types/stream';

const cleanBooleans = (object: { [key: string]: string | boolean }) => {
  const copy = { ...object };

  Object.keys(copy).forEach(k => {
    if (copy[k] === 'true') {
      copy[k] = true;
    } else if (copy[k] === 'false') {
      copy[k] = false;
    }
  });

  return copy;
};

const parseDestinationExtra = (destination: SocialDestination) => {
  try {
    if (!destination.extra) return {};

    const object =
      typeof destination.extra === 'object'
        ? destination.extra
        : toObject<{ [key: string]: string | boolean }>(destination.extra as string, true, ';');

    return cleanBooleans(object);
  } catch {
    throw new Error('Parse destination extra error');
  }
};

export default parseDestinationExtra;
